import { FC } from "react"
import { useManageUsers } from "./ManageUsersContext"
import { useNavigate } from "react-router-dom"

interface Props {
  user: IUser
}
const UserListItemView: FC<Props> = (props) => {
  const { selectedUser } = useManageUsers()
  const navigate = useNavigate()
  return (
    <div onClick={() => {
      navigate(`/manage/users?selected-user-id=${props.user.uid}`)
    }} className={`flex flex-col items-start p-2  rounded min-h-[50px] group relative ${selectedUser?.uid === props.user.uid ? "bg-primary" : "bg-gray-50 dark:bg-gray-800"} transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 cursor-pointer`}>
      <h5 className="text-sm font-thin text-gray-700 dark:text-white">
        {props.user.name}
      </h5>
      <p className="text-xs font-thin text-gray-700 dark:text-white">
        {props.user.email}
      </p>
      <p className="text-xs font-thin text-gray-700 dark:text-white">
        {props.user.persona}
      </p>
      <span className="text-black dark:text-white font-thin text-[10px]  w-full">{new Date(props.user.createdAt?.seconds * 1000).toDateString()}</span>
    </div>
  )
}

export default UserListItemView