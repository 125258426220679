import { object, string } from "yup"
import { useState } from "react"
import { Form, Formik } from "formik"
import ModalAlert, { ModalAlertProps } from "../../../components/interaction/ModalAlert"
import TextFieldInput from "../../../components/forms/inputs/TextFieldInput"
import EmailFieldInput from "../../../components/forms/inputs/EmailFieldInput"
import PasswordFieldInput from "../../../components/forms/inputs/PasswordFieldInput"
import SubmitButton from "../../../components/forms/inputs/SubmitButton"
import { auth, db } from "../../../firebase/client"
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth"
import { doc, setDoc, Timestamp } from "firebase/firestore"
import { useSearchParams } from "react-router-dom"

const RegisterFromValidation = object({
    fullname: string().trim().required().min(2, "Too short").max(40, "Too long"),
    email: string().trim().email().required().min(2, "Too short").max(50, "Too long"),
    password: string().trim().required().min(2, "Too short").max(15, "Too long"),
})


const RegisterFormView = () => {

    const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
        options: {
            visible: false,
            actionClick: () => { },
            severity: "success"
        }
    })

    const [searchParams] = useSearchParams();
    const referral = searchParams.get("referral");

    return (
        <div className="bg-white dark:bg-gray-800 shadow-md rounded lg:px-8 px-2 pt-6 pb-8 mb-4 lg:w-[500px] w-full h-auto">
            <ModalAlert options={modalAlert.options} />
            <Formik
                validationSchema={RegisterFromValidation}
                initialValues={{
                    fullname: "",
                    email: "",
                    password: ""
                }}
                onSubmit={async (value, { setSubmitting }) => {

                    try {

                        const user = await createUserWithEmailAndPassword(auth, value.email, value.password)
                        await sendEmailVerification(user.user)
                        const usersRef = doc(db, "users", user.user.uid)

                        await setDoc(usersRef, {
                            uid: user.user.uid,
                            email: value.email,
                            name: value.fullname,
                            balance: 0,
                            persona: "customer",
                            createdAt: Timestamp.now()
                        })

                        if (referral) {

                        }

                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: "You need to sign in to your account now",
                                    severity: "success",
                                    actionClick: () => {
                                        window.location.replace("/signin");
                                    },
                                    actionText: "Login"
                                }
                            }
                        })


                    } catch (error: any) {

                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: error.message,
                                    severity: "failed",
                                    actionClick: () => {

                                        setModalAlert((prev) => {
                                            return {
                                                options: {
                                                    ...prev.options,
                                                    visible: false
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }

                    setSubmitting(false)

                }}>
                {({ isSubmitting, submitForm }) => <Form className="flex flex-col gap-5">

                    <TextFieldInput name="fullname" label="Full name" />
                    <EmailFieldInput name="email" label="Email" />
                    <PasswordFieldInput name="password" label="Password" />
                    <SubmitButton loading={isSubmitting} disabled={isSubmitting} onClick={submitForm} />
                    <p className="text-gray-900 dark:text-gray-100 font-thin">Need to login to your account? click <a href="/session/signin" className="underline underline-offset-1">here</a></p>
                </Form>}
            </Formik>
        </div>
    )
}

export default RegisterFormView