import { RouteObject } from "react-router-dom"
import DashboardLayoutView from "../views/dashboard-views/dashboard-layout/DashboardLayoutView"
import DashboardView from "../views/dashboard-views/dashboard/DashboardView"
import TransactionView from "../views/dashboard-views/transaction/TransactionView"
import MarketplaceView from "../views/dashboard-views/marketplace/MarketplaceView"
import TopupView from "../views/dashboard-views/topup/TopupView"
import MarketplaceProductDetailsView from "../views/dashboard-views/marketplace/MarketplaceProductDetailsView"
import ToolSetupView from "../views/dashboard-views/tools/ToolSetupView"
import ToolManageView from "../views/dashboard-views/tools/ToolManageView"
import MyToolsView from "../views/dashboard-views/tools/MyToolsView"


const DashboardRoutes: RouteObject[] = [
    {
        path: "/dashboard",
        element: <DashboardLayoutView />,
        children: [
            {
                path: "home",
                element: <DashboardView />
            },
            {
                path: "transactions",
                element: <TransactionView />
            },
            {
                path: "marketplace",
                element: <MarketplaceView />
            },
            {
                path: "marketplace/:productId",
                element: <MarketplaceProductDetailsView />
            },
            {
                path: "my-tools",
                element: <MyToolsView />
            },
            {
                path: "my-tools/:toolId",
                element: <ToolManageView />
            },
            {
                path: "my-tools/setup/:toolId",
                element: <ToolSetupView />
            },
            {
                path: "top-up",
                element: <TopupView />
            }
        ]
    }
]


export default DashboardRoutes