import { Field } from "formik"
import type { FC } from "react"


interface TextFieldInputProps {
    name: string
    label?: string
    placeholder?: string
}
const TextFieldInput: FC<TextFieldInputProps> = (props) => {
    return (
        <Field name={props.name}>
            {({ field, _, meta }: any) => (
                <div>
                    {
                        props.label ? <label htmlFor={props.name} className="text-gray-900 dark:text-white">{props.label}</label> : null
                    }
                    <input id={props.name} type="text" {...field} placeholder={props.placeholder ? props.placeholder : ``} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" />
                    {meta.touched &&
                        meta.error && <div className="text-red-700">{meta.error}</div>}
                </div>
            )}
        </Field>
    )
}

export default TextFieldInput