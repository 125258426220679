import { FC } from "react"
import ManageToolsListItemView from "./ManageToolsListItemView"

interface Props {
    tools: ITool[]
}
const ManageToolsListView: FC<Props> = (props) => {
    return (
        <div className="flex flex-col gap-2">
            {
                props.tools.map((tool) => <ManageToolsListItemView key={tool.id} tool={tool} />)
            }
        </div>
    )
}

export default ManageToolsListView