import CallToActionView from "./components/CallToActionView"
import FeaturesView from "./components/FeaturesView"
import HeroSectionView from "./components/HeroSectionView"
import TestimonialsView from "./components/TestimonialsView"

const HomeView = () => {
  return (
    <main
      className="space-y-40 mb-40"
    >
      <HeroSectionView />
      <FeaturesView />
      <TestimonialsView />
      <CallToActionView />
    </main>
  )
}

export default HomeView