import { useState, type FC } from "react"
import TransactionListItemDetailView from "./TransactionListItemDetailView"

interface TransactionListItemViewProps {
    transaction: ITransaction
}
const TransactionListItemView: FC<TransactionListItemViewProps> = (props) => {

    const [showDetais, setShowDetais] = useState(false)

    const getIcon = (scope: ITransactionScope) => {
        switch (scope) {
            case "balance":
                return <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                    />
                </svg>
            case "purchase":
                return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg>


            default:
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                    />
                </svg>
        }
    }

    const getTitle = (scope: ITransactionScope) => {
        switch (scope) {
            case "balance":
                return "Balance top up"
            case "purchase":
                return "Product purchase"
            default:
                return ""
        }
    }

    const getStatus = (status: ITransactionStatus) => {
        switch (status) {
            case "complete":
                return <span className="text-[10px] text-green-700 font-light">Success</span>
            case "failed":
                return <span className="text-[10px] text-red-700 font-light">Failed</span>
            case "initiated":
                return <span className="text-[10px] text-yellow-700 font-light">Initiated</span>
            case "pending":
                return <span className="text-[10px] text-yellow-700 font-light">Pending</span>
            default:
                return <span className="text-[10px] text-yellow-700 font-light">Initiated</span>
        }
    }
    return (
        <>
            {showDetais ? <TransactionListItemDetailView onClose={() => { setShowDetais(false) }} transaction={props.transaction} /> : null}

            <div onClick={() => { setShowDetais(true) }} className=" flex items-center gap-2 p-4  rounded col-span-1 min-h-[50px] group relative bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 cursor-pointer">

                <div className="grow flex flex-col items-start">
                    <h5 className="text-sm font-thin text-gray-700 dark:text-white">
                        {getTitle(props.transaction.scope)}
                    </h5>
                    <p className="text-xs font-thin text-gray-700 dark:text-white">
                        {new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(props.transaction.amount)}
                    </p>
                    <div className="flex items-center">
                        {getStatus(props.transaction.status)}
                    </div>
                    <span className="text-black dark:text-white font-thin text-[10px]  w-full">{new Date(props.transaction.createdAt?.seconds * 1000).toDateString()}</span>
                </div>
                <div className="w-10 h-10 flex items-center justify-center rounded-md bg-slate-200">
                    {getIcon(props.transaction.scope)}
                </div>
            </div>
        </>

    )
}

export default TransactionListItemView