import type { FC } from "react"
import MarketplaceListItemView from "./MarketplaceListItemView"

interface MarketplaceListViewProps {
    products: IProduct[]
    isShorcut?: boolean
}
const MarketplaceListView: FC<MarketplaceListViewProps> = (props) => {
    return (
        <div className={"flex flex-col gap-2"}>
        {/* <div className={`grid grid-flow-row grid-cols-1 ${props.isShorcut ? 'lg:grid-cols-2' : 'lg:grid-cols-4'}  gap-2`}> */}

            {
                props.products.map((product) =>
                    <MarketplaceListItemView key={product.id} product={product} isShorcut={props.isShorcut}/>

                )
            }
        </div>
    )
}

export default MarketplaceListView