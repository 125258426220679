
import { collection, query, where } from "firebase/firestore"
import QueryCountView from "../../../../components/common/QueryCountView"
import { useManageUsers } from "./ManageUsersContext"
import { db } from "../../../../firebase/client"
import { useNavigate, useSearchParams } from "react-router-dom"

const UserResourcesView = () => {
    const { selectedUser } = useManageUsers()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const listParam = searchParams.get("list");

    if (selectedUser === undefined) {
        return <div className="text-black dark:text-white text-2xl flex flex-col items-center justify-center text-center h-[200px] group  bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
            Select from the list to show resources
        </div>
    }
    return (
        <div className="flex flex-col gap-1 p-2  rounded h-[300px] group relative bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
            <h5 className="text-sm font-thin text-gray-700 dark:text-white">
                {selectedUser?.name}
            </h5>
            <p className="text-xs font-thin text-gray-700 dark:text-white">
                {selectedUser?.email}
            </p>
            <p className="text-xs font-thin text-gray-700 dark:text-white">
                {selectedUser?.persona}
            </p>
            <hr />
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded">
                <p className="text-xs font-thin text-gray-700 dark:text-white">
                    Balance
                </p>
                <p className="text-xs font-thin text-gray-700 dark:text-white">
                    {new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(selectedUser?.balance || 0)}
                </p>
            </div>
            <div className={`flex items-center justify-between p-4 ${listParam === "tools" ? "bg-primary" : "bg-gray-50 dark:bg-gray-700"} rounded`} onClick={() => {
                navigate(`/manage/users?selected-user-id=${selectedUser.uid}&list=tools`)
            }}>
                <p className="text-xs font-thin text-gray-700 dark:text-white">
                    Tools
                </p>
                <div className="flex items-center gap-2">
                    <p className="text-xs font-thin text-gray-700 dark:text-white">
                        <QueryCountView query={query(collection(db, "tools"), where("userId", "==", selectedUser.uid))} />
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-black">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                    </svg>
                </div>

            </div>
            <div className={`flex items-center justify-between p-4 ${listParam === "transactions" ? "bg-primary" : "bg-gray-50 dark:bg-gray-700"} rounded`} onClick={() => {
                navigate(`/manage/users?selected-user-id=${selectedUser.uid}&list=transactions`)
            }}>
                <p className="text-xs font-thin text-gray-700 dark:text-white">
                    Transactions
                </p>
                <div className="flex items-center gap-2">
                    <p className="text-xs font-thin text-gray-700 dark:text-white">
                        <QueryCountView query={query(collection(db, "transactions"), where("userId", "==", selectedUser.uid))} />
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-black">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                    </svg>
                </div>

            </div>
        </div>
    )
}

export default UserResourcesView