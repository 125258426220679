import { FieldArray, Form, Formik } from "formik"
import SubmitButton from "../../../../components/forms/inputs/SubmitButton"
import TextFieldInput from "../../../../components/forms/inputs/TextFieldInput"
import SelectFieldInput from "../../../../components/forms/inputs/SelectFieldInput"
import { object, string } from "yup"
import { FC, useState } from "react"
import ModalAlert, { ModalAlertProps } from "../../../../components/interaction/ModalAlert"
import { doc, setDoc } from "firebase/firestore"
import { db } from "../../../../firebase/client"
import { useNavigate } from "react-router-dom"

const FullAutomationFormValidation = object({
    programToPromote: string().trim().required("Required!"),
    promoteMoreThanOneProduct: string().trim().required("Required!"),
    systemioEmail: string().trim().required("Required!"),
    systemioPassword: string().trim().required("Required!"),
    autoresponder: string().trim().required("Required!"),
    // programToPromoteOther: string()
    //     .test("othersSelected", "Enter Other program to promote!", (val, context) => {
    //         return context.parent.programToPromote === "Others" && val !== ""
    //     }),
    // productCountToPromote: string()
    //     .test("moreThanOneProductToPromote", "Select the number of website to promote!", (val, context) => {
    //         return context.parent.promoteMoreThanOneProduct === "Yes" && val !== undefined
    //     }),
    // productsToPromote: array()
    //     .test("productToPromote", "Enter website to promote!", (val, context) => {
    //         console.log("val", val);

    //         return parseInt(context.parent.productCountToPromote) > 0 && val !== undefined
    //     }),
})

interface Props {
    toolId: string
}
const FullAutomationFormView: FC<Props> = (props) => {

    const navigate = useNavigate()
    const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
        options: {
            visible: false,
            actionClick: () => { },
            severity: "success"
        }
    })


    return (
        <>
            <ModalAlert options={modalAlert.options} />
            <Formik
                validationSchema={FullAutomationFormValidation}
                initialValues={{
                    programToPromote: "",
                    programToPromoteOther: "",
                    promoteMoreThanOneProduct: "",
                    productCountToPromote: "",
                    productExtraLeads: "",
                    productsToPromote: ['', '', '', '', '', '', '', '', '', ''],
                    productToPromote: "",
                    systemioEmail: "",
                    systemioPassword: "",
                    autoresponder: "",
                }}
                onSubmit={async (values,{setSubmitting}) => {
                    let setup: IToolSetupField[] = []
                    for (const [key, value] of Object.entries(values)) {

                        if (key === "programToPromote") {
                            setup.push({
                                label: "Which Programs would you like to promote ?",
                                answer: value
                            })
                        }

                        if (key === "programToPromoteOther") {
                            setup.push({
                                label: "Enter other program to promote",
                                answer: value
                            })
                        }

                        if (key === "promoteMoreThanOneProduct") {
                            setup.push({
                                label: "Would you like to promote more than one product ?",
                                answer: value
                            })
                        }
                        if (key === "productCountToPromote") {
                            setup.push({
                                label: "How many products do you want to promote ?",
                                answer: value
                            })
                        }
                        if (key === "productExtraLeads") {
                            setup.push({
                                label: "Would you like to add extra 500 leads on each products ?",
                                answer: value
                            })
                        }
                        if (key === "productToPromote") {
                            setup.push({
                                label: "Input the url of the website you want to promote",
                                answer: value
                            })
                        }
                        if (key === "systemioEmail") {
                            setup.push({
                                label: "System io Email",
                                answer: value
                            })
                        }
                        if (key === "systemioPassword") {
                            setup.push({
                                label: "System io Password",
                                answer: value
                            })
                        }
                        if (key === "autoresponder") {
                            setup.push({
                                label: "Do you need capture page in your autoresponder ?",
                                answer: value
                            })
                        }
                        if (key === "productsToPromote") {
                            setup.push({
                                label: "Input the url(s) of the website you want to promote",
                                answer: value
                            })
                        }
                    }
                    let amount = 0

                    if (values.promoteMoreThanOneProduct === "Yes") {
                        const baseTotal = parseInt(values.productCountToPromote) * 200
                        amount += baseTotal

                        if (values.productExtraLeads === "Yes") {
                            const extraTotal = parseInt(values.productCountToPromote) * 500
                            amount += extraTotal
                        }
                    }




                    try {
                        const toolDocRef = doc(db, "tools", props.toolId)

                        await setDoc(toolDocRef, {
                            setup,
                            pendingPayment: {
                                amount,
                                paid: false
                            }
                        }, { merge: true })


                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: `Setup was  successful`,
                                    severity: "success",
                                    actionClick: () => {
                                        navigate(`/dashboard/my-tools/${props.toolId}`)
                                    },
                                    actionText: "Go to My Tool"
                                }
                            }
                        })
                    } catch (error: any) {

                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: error.message,
                                    severity: "failed",
                                    actionClick: () => {

                                        setModalAlert((prev) => {
                                            return {
                                                options: {
                                                    ...prev.options,
                                                    visible: false
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }

                    setSubmitting(false)
                }}
            >
                {({ isSubmitting, submitForm, values }) =>
                    <Form className="flex flex-col gap-3">
                        <SelectFieldInput
                            name="programToPromote"
                            label="Which Programs would you like to promote ?"
                            options={["IPS", "LEPO", "LBP", "Beach Boss", "UBC", "Simply Passive", "Roadmap", "SWC", "Others"]} />
                        {
                            values.programToPromote === "Others" ? <TextFieldInput name="programToPromoteOther" label="Enter other program to promote" /> : <></>
                        }
                        <SelectFieldInput
                            name="promoteMoreThanOneProduct"
                            label="Would you like to promote more than one product ?"
                            options={["Yes", "No"]} />
                        {
                            values.promoteMoreThanOneProduct === "Yes" ?
                                <SelectFieldInput
                                    label="How many products do you want to promote ?"
                                    name="productCountToPromote"
                                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                                    helper={values.productCountToPromote === "" ? undefined : `${values.productCountToPromote} more products will costs ${Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(parseInt(values.productCountToPromote) * 200)}`}
                                />

                                : <></>
                        }
                        {
                            values.productCountToPromote !== "" && values.promoteMoreThanOneProduct === "Yes" ?
                                <SelectFieldInput
                                    label="Would you like to add extra 500 leads on each products ?"
                                    name="productExtraLeads"
                                    options={["Yes", "No"]}
                                    helper={values.productExtraLeads === "Yes" ? `${values.productCountToPromote} products will costs ${Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(parseInt(values.productCountToPromote) * 500)} extra` : undefined}
                                />

                                : <></>
                        }




                        {
                            values.productCountToPromote && values.promoteMoreThanOneProduct === "Yes"
                                ? <div className="p-1 border border-gray-700 rounded">
                                    <label htmlFor={"productsToPromote"} className="text-gray-900 dark:text-white">Input the url(s) of the website you want to promote</label>
                                    <FieldArray
                                        name="productsToPromote"
                                        render={() => (
                                            <>
                                                {
                                                    Array.from({ length: values.promoteMoreThanOneProduct === "No" ? 1 : parseInt(values.productCountToPromote) || 1 }, (_value, _index) => _index).map((_, index) => <TextFieldInput key={`productsToPromote.${index}`} name={`productsToPromote.${index}`} label={`Product ${index + 1}`} />)
                                                }

                                            </>
                                        )}
                                    />
                                </div> : <TextFieldInput name="productToPromote" label="Input the url of the website you want to promote" />
                        }




                        <div className="flex flex-col gap-3 p-1 border border-gray-700 rounded">
                            <label className="text-gray-900 dark:text-white">Please input your system io login information</label>
                            <TextFieldInput name="systemioEmail" label="Email" />
                            <TextFieldInput name="systemioPassword" label="Password" />
                        </div>

                        <SelectFieldInput
                            label="Do you need capture page in your autoresponder ?"
                            name="autoresponder"
                            options={["Yes", "No"]}
                        />
                        <SubmitButton loading={isSubmitting} disabled={isSubmitting} onClick={submitForm} />

                        <p className="text-gray-900 dark:text-white">
                            The whole setup takes a week to be complete, you will be notified upon completion, or if there is need for extra information from you. kindly make sure you have the 2FA authentication turned of for system.io. If it isn't turned of, you can send them an email to turn it off.
                        </p>
                    </Form>}
            </Formik>
        </>
    )
}

export default FullAutomationFormView