import { useState } from "react"
import { object, string } from "yup"
import ModalAlert, { ModalAlertProps } from "../../../components/interaction/ModalAlert"
import { Form, Formik } from "formik"
import EmailFieldInput from "../../../components/forms/inputs/EmailFieldInput"
import SubmitButton from "../../../components/forms/inputs/SubmitButton"
import { useUserAuth } from "../../../context/userAuthContext"
import { useNavigate } from "react-router-dom"


const ForgotPasswordFormValidation = object({
    email: string().trim().email().required().min(2, "Too short").max(50, "Too long"),
})

const ForgotPasswordFormView = () => {

    const { sendPasswordReset } = useUserAuth()

    const navigate = useNavigate()

    const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
        options: {
            visible: false,
            actionClick: () => { },
            severity: "success"
        }
    })
    return (
        <div className="bg-white dark:bg-gray-800 shadow-md rounded lg:px-8 px-2 pt-6 pb-8 mb-4 lg:w-[500px] w-full h-auto">
            <ModalAlert options={modalAlert.options} />
            <Formik
                validationSchema={ForgotPasswordFormValidation}
                initialValues={{
                    email: ""
                }}
                onSubmit={async (value, { setSubmitting }) => {

                    const onError = (errMsg: string) => {
                        setSubmitting(false)
                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: errMsg,
                                    severity: "failed",
                                    actionClick: () => {

                                        setModalAlert((prev) => {
                                            return {
                                                options: {
                                                    ...prev.options,
                                                    visible: false
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }

                    const onSuccess = () => {
                        setSubmitting(false)
                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: "Go to your email and click on reset password link",
                                    severity: "success",
                                    actionText: "Go to login",
                                    actionClick: () => {
                                        navigate("/session/signin")
                                    }
                                }
                            }
                        })
                    }


                    sendPasswordReset(value.email, onError, onSuccess)




                }}>
                {({ isSubmitting, submitForm }) => <Form className="flex flex-col gap-5">
                    <EmailFieldInput name="email" label="Email" />
                  
                    <SubmitButton loading={isSubmitting} disabled={isSubmitting} onClick={submitForm} />
                    <p className="text-gray-900 dark:text-gray-100 font-thin">Are you new here? click <a href="/session/register" className="underline underline-offset-1">here</a></p>
                    
                </Form>}
            </Formik>
        </div>
    )
}

export default ForgotPasswordFormView