import { useNavigate, useParams } from "react-router-dom"
import SubmitButton from "../../../components/forms/inputs/SubmitButton"
import { useEffect, useState } from "react"
import ModalAlert, { ModalAlertProps } from "../../../components/interaction/ModalAlert"
import { useUserAuth } from "../../../context/userAuthContext"

const VerifyEmailView = () => {
    const { email } = useParams()
    const [processing, setProcessing] = useState(false)
    const [countdown, setCountdown] = useState(0);

    const {resendEmailVerification} = useUserAuth()

    const navigate = useNavigate()

    const handleClick = () => {
        setProcessing(true);
        setCountdown(120); // Set countdown to 60 seconds

        const onError = (errMsg: string) => {
            setModalAlert((prev) => {
                return {
                    options: {
                        ...prev.options,
                        visible: true,
                        message: errMsg,
                        severity: "failed",
                        actionClick: () => {

                            setModalAlert((prev) => {
                                return {
                                    options: {
                                        ...prev.options,
                                        visible: false
                                    }
                                }
                            })
                        }
                    }
                }
            })
        }

        const onSuccess = () => {
            
            setModalAlert((prev) => {
                return {
                    options: {
                        ...prev.options,
                        visible: true,
                        message: "Go to your email and click on verification link to verify your email,",
                        severity: "success",
                        actionText:"Go to login",
                        actionClick: () => {
                            navigate("/session/signin")
                        }
                    }
                }
            })
        }
        resendEmailVerification(onError, onSuccess)
    };

    const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
        options: {
            visible: false,
            actionClick: () => { },
            severity: "success"
        }
    })



    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (processing && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000); // Decrease the countdown every second
        } else if (countdown === 0) {
            setProcessing(false); // Re-enable the button after countdown ends
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [countdown, processing]);

    return (
        <div className="w-full h-[100vh]  flex flex-col items-center justify-center">
            <ModalAlert options={modalAlert.options} />
            <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl mb-5"> <span className="text-primary dark:text-white">Verify Email</span></h1>
            <span className="text-sm text-center font-thin text-gray-700 dark:text-white my-5">{email}</span>
            <SubmitButton
                loading={processing}
                disabled={processing}
                onClick={handleClick}
                btnText="Resend verification" />
            {
                processing ? <span className="text-sm text-center font-thin text-gray-700 dark:text-white my-2">{`Resend in ${countdown} seconds...`}</span> : ""
            }

        </div>
    )
}

export default VerifyEmailView