import ForgotPasswordFormView from "./ForgotPasswordFormView"

const ForgotPasswordView = () => {
    return (
        <div className="w-full h-[100vh]  flex flex-col items-center justify-center">
            <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl mb-5"> <span className="text-primary dark:text-white">Forgot Password</span></h1>
            <ForgotPasswordFormView />
        </div>
    )
}

export default ForgotPasswordView