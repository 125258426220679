import React, { FC, useState } from 'react'
import SubmitButton from '../../../../components/forms/inputs/SubmitButton'
import { addDoc, collection, doc, increment, setDoc, Timestamp, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase/client'

interface Props {
    topup: ITopup
}
const TopupListItemView: FC<Props> = (props) => {
    const [isProcessing, setIsProcessing] = useState(false)

    return (
        <div className=" flex flex-col  gap-2 p-4  rounded col-span-1 min-h-[50px] group relative bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 cursor-pointer">
            <p className="text-xs font-thin text-gray-700 dark:text-white">{props.topup.name}</p>
            <p className="text-xs font-thin text-gray-700 dark:text-white">{props.topup.email}</p>
            <span className="text-black dark:text-white font-thin text-[10px]  w-full">{new Date(props.topup.createdAt?.seconds * 1000).toDateString()}</span>
            <SubmitButton
                loading={isProcessing}
                disabled={isProcessing}
                onClick={async () => {
                    setIsProcessing(true)
                    const transactionsRef = collection(db, "transactions")

                    const transaction = await addDoc(transactionsRef, {
                        userId: props.topup.uid,
                        amount: props.topup.amount,
                        scope: "balance",
                        status: "initiated",
                        createdAt: Timestamp.now()
                    })

                    await setDoc(doc(db, "transactions", transaction.id), {
                        id: transaction.id,
                        status: "complete"
                    }, { merge: true })

                    await updateDoc(doc(db, "users", props.topup.uid), {
                        balance: increment(props.topup.amount)
                    })

                    await updateDoc(doc(db, "topups", props.topup.id), {
                        fulfied: true
                    })
                    setIsProcessing(false)
                    window.location.reload();

                }}
                btnText={`Confirm ${new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(props.topup.amount)}`} />
        </div>
    )
}

export default TopupListItemView