import type { FC } from "react"
import ToolListItemView from "./ToolListItemView"

interface ToolListViewProps {
    tools: ITool[]
}
const ToolListView: FC<ToolListViewProps> = (props) => {

    return (

        <div className="flex flex-col gap-2">
            {
                props.tools.map((tool) => <ToolListItemView key={tool.id} tool={tool} />)
            }
        </div>

    )
}

export default ToolListView