import { useState } from "react"
import { object, string } from "yup"
import ModalAlert, { ModalAlertProps } from "../../../components/interaction/ModalAlert"
import { Form, Formik } from "formik"
import EmailFieldInput from "../../../components/forms/inputs/EmailFieldInput"
import PasswordFieldInput from "../../../components/forms/inputs/PasswordFieldInput"
import SubmitButton from "../../../components/forms/inputs/SubmitButton"
import { useUserAuth } from "../../../context/userAuthContext"
import { useNavigate } from "react-router-dom"

const SignInFormValidation = object({

    email: string().trim().email().required().min(2, "Too short").max(50, "Too long"),
    password: string().trim().required().min(2, "Too short").max(15, "Too long"),
})

const SignInForm = () => {

    const { signIn } = useUserAuth()

    const navigate = useNavigate()

    const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
        options: {
            visible: false,
            actionClick: () => { },
            severity: "success"
        }
    })
    return (
        <div className="bg-white dark:bg-gray-800 shadow-md rounded lg:px-8 px-2 pt-6 pb-8 mb-4 lg:w-[500px] w-full h-auto">
            <ModalAlert options={modalAlert.options} />
            <Formik
                validationSchema={SignInFormValidation}
                initialValues={{
                    email: "",
                    password: ""
                }}
                onSubmit={async (value, { setSubmitting }) => {

                    const onError = (errMsg: string) => {
                        setSubmitting(false)
                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: errMsg,
                                    severity: "failed",
                                    actionClick: () => {

                                        setModalAlert((prev) => {
                                            return {
                                                options: {
                                                    ...prev.options,
                                                    visible: false
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }

                    const onSuccess = () => {
                        setSubmitting(false)
                        navigate("/dashboard/home")
                    }

                    const onEmailNotVerified = () => {
                        setSubmitting(false)
                        setModalAlert((prev) => {
                            return {
                                options: {
                                    ...prev.options,
                                    visible: true,
                                    message: "Your Email is yet to be verified!",
                                    severity: "failed",
                                    actionText:"Go to verify email",
                                    actionClick: () => {

                                        navigate(`/session/verify-email/${value.email}`)
                                    }
                                }
                            }
                        })
                    }
                    signIn(value, onError, onSuccess,onEmailNotVerified)

                    // const formData = new FormData();

                    // formData.append("email", value.email);
                    // formData.append("password", value.password);

                    // const auth = getAuth(app);
                    // auth.setPersistence(inMemoryPersistence);



                    // try {
                    //     const userCredential = await signInWithEmailAndPassword(
                    //         auth,
                    //         value.email,
                    //         value.password
                    //     );
                    //     const idToken = await userCredential.user.getIdToken();
                    //     const response = await fetch("/api/auth/signin", {
                    //         headers: {
                    //             Authorization: `Bearer ${idToken}`,
                    //         },
                    //     });

                    //     if (response.redirected) {
                    //         window.location.assign(response.url);
                    //     }

                    // } catch (error) {


                    // }

                   

                }}>
                {({ isSubmitting, submitForm }) => <Form className="flex flex-col gap-5">
                    <EmailFieldInput name="email" label="Email" />
                    <PasswordFieldInput name="password" label="Password" />
                    <SubmitButton loading={isSubmitting} disabled={isSubmitting} onClick={submitForm} />
                    <p className="text-gray-900 dark:text-gray-100 font-thin">Are you new here? click <a href="/session/register" className="underline underline-offset-1">here</a></p>
                    <p className="text-gray-900 dark:text-gray-100 font-thin">Forgot password? click <a href="/session/forgot-password" className="underline underline-offset-1">here</a></p>
                </Form>}
            </Formik>
        </div>
    )
}

export default SignInForm