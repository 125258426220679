import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"
import { db } from "../../../firebase/client"
import ManageToolsListView from "./components/ManageToolsListView"
import ManageToolsListSkeletonView from "./components/ManageToolsListSkeletonView"
import ManageToolsResourcesView from "./components/ManageToolsResourcesView"
import { useSearchParams } from "react-router-dom"
import { useManageTools } from "./components/ManageToolsContext"
import ManageToolStatusCountView from "./components/ManageToolsCountView"

const ManageToolsView = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const tabs = useMemo(() => [
    {
      title: "Pending",
      value: "pending",
      count: <ManageToolStatusCountView status="pending" />
    },
    {
      title: "In Progress",
      value: "in-progress",
      count: <ManageToolStatusCountView status="in-progress" />
    },
    {
      title: "Complete",
      value: "complete",
      count: <ManageToolStatusCountView status="complete" />
    },
  ], [])

  const [tools, setToolView] = useState<ITool[]>([])
  const [lastVisibleTool, setLastVisibleTool] = useState<any>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const [searchParams] = useSearchParams();
  const tabIndexParam = searchParams.get("tab-index");
  const selectedToolIdParam = searchParams.get("selected-tool-id");

  const { setSelectedTool } = useManageTools()

  useEffect(() => {
    (async () => {
      try {

        setToolView([])
        setIsLoading(true)
        const first = query(collection(db, "tools"), where("status", "==", tabs[tabIndex].value), orderBy("createdAt", "desc"), limit(10));
        const documentSnapshots = await getDocs(first);
        documentSnapshots.forEach((doc) => {
          setToolView(prev => [...prev, doc.data() as ITool])
        })
        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        setLastVisibleTool(lastVisible)
        setIsLoading(false)


      } catch (error: any) {
        console.log(error.message);

      }
    })()
  }, [tabIndex, tabs])

  useEffect(() => {
    const _tabIndex = parseInt(tabIndexParam || "0") || tabIndex
    setTabIndex(_tabIndex)
  }, [tabIndexParam, tabIndex])

  useEffect(() => {
    if (selectedToolIdParam) {
      const _selectedTool = tools.find((tool) => tool.id === selectedToolIdParam)
      setSelectedTool(_selectedTool)
    }
  }, [selectedToolIdParam, tools, setSelectedTool])


  return (
    <div>
      <h1
        className="text-black dark:text-gray-300 text-2xl md:text-4xl font-bold my-8"
      >
        Manage Tools
      </h1>

      <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-10  gap-2">
        <div className="col-span-5">
          <div className="flex items-center my-4">
            {
              tabs.map((tab, index) =>
                <div
                  key={tab.value}
                  className={`text-black dark:text-white font-light px-4 py-2 w-fit flex items-center ${tabIndex === index ? "bg-primary rounded-full" : ""}`}
                  onClick={() => {
                    setSelectedTool(undefined)
                    setTabIndex(index)
                  }}
                >
                  {tab.title}
                  {tab.count}
                </div>
              )
            }
          </div>
          {
            isLoading ?
              <ManageToolsListSkeletonView />
              : <ManageToolsListView tools={tools} />
          }

          {
            lastVisibleTool && <button
              onClick={async () => {
                setIsLoadingMore(true)
                const more = query(collection(db, "tools"), where("status", "==", tabs[tabIndex].value), orderBy("createdAt", "desc"), startAfter(lastVisibleTool), limit(10));
                const documentSnapshots = await getDocs(more);
                documentSnapshots.forEach((doc) => {
                  setToolView(prev => [...prev, doc.data() as ITool])
                })
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                setLastVisibleTool(lastVisible)
                setIsLoadingMore(false)
              }}
              className="mt-10 relative flex h-10 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              disabled={isLoadingMore}
            >
              {
                isLoadingMore ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 animate-spin w-5 stroke-white dark:stroke-dark">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                  : <span className="relative text-base font-semibold text-white dark:text-dark"
                  >Show more</span>
              }

            </button>
          }

        </div>
        <div className="col-span-5">
          <ManageToolsResourcesView />
        </div>
      </div>



    </div>
  )
}

export default ManageToolsView
