import type { FC } from "react"
import TopupListItemView from "./TopupListItemView"

interface TopupListViewProps {
  topups: ITopup[]
}
const TopupListView: FC<TopupListViewProps> = (props) => {
  return (
    <div className="flex flex-col gap-2">
      {
        props.topups.map((topup) => <TopupListItemView key={topup.id} topup={topup} />)
      }
    </div>
  )
}

export default TopupListView