import {
    createBrowserRouter,
} from "react-router-dom";
import LandingRoutes from "./landing.routes";
import SessionRoutes from "./session.routes";
import DashboardRoutes from "./dashboard.routes";
import AdminRoutes from "./admin.routes";

const router = createBrowserRouter([
    ...LandingRoutes,
    ...SessionRoutes,
    ...DashboardRoutes,
    ...AdminRoutes
]);

export default router