import {
    RouteObject,
} from "react-router-dom";
import LandingLayoutView from "../views/landing-views/landing-layout/LandingLayoutView";
import HomeView from "../views/landing-views/home/HomeView";
import ContactView from "../views/landing-views/contact/ContactView";
import AboutView from "../views/landing-views/about/AboutView";
import TermsOfUseView from "../views/landing-views/terms-of-use/TermsOfUseView";
import PrivacyPolicyView from "../views/landing-views/privacy-policy/PrivacyPolicyView";
import RefundPolicyView from "../views/landing-views/refund-policy/RefundPolicyView";
import FAQView from "../views/landing-views/faq/FAQView";

const LandingRoutes: RouteObject[] = [
    {
        path: "/",
        element: <LandingLayoutView />,
        children: [
            {
                path: "/",
                element: <HomeView />
            },
            {
                path: "contact",
                element: <ContactView />
            },
            {
                path: "about",
                element: <AboutView />
            },
            {
                path: "terms-of-use",
                element: <TermsOfUseView />
            },
            {
                path: "privacy-policy",
                element: <PrivacyPolicyView />
            },
            {
                path: "refund-policy",
                element: <RefundPolicyView />
            },
            {
                path: "faq",
                element: <FAQView />
            },
        ]
    }
]


export default LandingRoutes