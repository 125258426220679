import { FC } from "react"
import ManageToolsResourcesHistoryListItemView from "./ManageToolsResourcesHistoryListItemView"

interface Props {
    toolResources: IToolResource[]
}
const ManageToolsResourcesHistoryListView: FC<Props> = (props) => {
    return (
        <div className="flex flex-col gap-2">
            {
                props.toolResources.map((toolResource) => <ManageToolsResourcesHistoryListItemView key={toolResource.id} toolResource={toolResource} />)
            }
        </div>
    )
}

export default ManageToolsResourcesHistoryListView