import { collection, getCountFromServer, query, where } from 'firebase/firestore'
import React, { FC, useEffect, useState } from 'react'
import { db } from '../../../../firebase/client'

interface Props {
    status: IToolStatus
}
const ManageToolStatusCountView: FC<Props> = (props) => {
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const snapshot = await getCountFromServer(query(collection(db, "tools"), where("status", "==", props.status)));
                setCount(snapshot.data().count)
                setIsLoading(false)
            } catch (error: any) {
                console.log(error.message);

            }
        })()
    }, [props.status])

    return (
        <>
            {
                isLoading ? "..." : <>({count})</>
            }
        </>
    )
}

export default ManageToolStatusCountView