import { FC } from "react"

interface Props {
    toolResource: IToolResource
}
const ManageToolsResourcesHistoryListItemView: FC<Props> = (props) => {
    return (
        <div className="flex items-center min-h-[50px] p-1 bg-purple-50 dark:bg-gray-700 rounded gap-3 relative">
            {
                props.toolResource.type === "link" ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-primary">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                </svg>
                    : <></>
            }
            {
                props.toolResource.type === "message" ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-primary">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>

                    : <></>
            }
            <div className="grow flex  flex-col ">
                <span className="text-black dark:text-white font-light text-sm">{props.toolResource.title}</span>
                {
                    props.toolResource.type === "link" ? <a href={props.toolResource.link} target="_blank" rel="noopener noreferrer" className="text-primary text-xs font-light underline decoration-primary underline-offset-1">View Link</a> : <></>
                }
                {
                    props.toolResource.type === "message" ? <span className="text-black dark:text-white font-thin text-xs">{props.toolResource.message}</span> : <></>
                }

            </div>

            <div className="absolute top-0 right-1">
                <span className="text-black dark:text-white font-thin text-xs text-right w-full">{new Date(props.toolResource.createdAt?.seconds * 1000).toDateString()}</span>
            </div>
        </div>
    )
}

export default ManageToolsResourcesHistoryListItemView