import { doc, getDoc } from "firebase/firestore"
import { useEffect, useState, type FC } from "react"
import { db } from "../../../../firebase/client"

interface RenderDatumProps {
    title: string
    value: string
}
const RenderDatum: FC<RenderDatumProps> = (props) => {
    return <div className="flex items-center justify-between w-full gap-10">
        <span className="text-sm font-thin text-gray-700 dark:text-white">{props.title}</span>
        <span className="text-sm font-light text-gray-700 dark:text-white text-right text-ellipsis truncate">{props.value}</span>
    </div>
}

interface TransactionListItemDetailViewProps {
    transaction: ITransaction
    onClose: () => void
}

const TransactionListItemDetailView: FC<TransactionListItemDetailViewProps> = (props) => {
    const [product, setProduct] = useState<IProduct | undefined>(undefined)
    const [isLoadingProduct, setIsLoadingProduct] = useState(false)

    useEffect(() => {
        (async () => {
            if (props.transaction.productId) {
                setIsLoadingProduct(true)
                const productDocRef = doc(db, "products", props.transaction.productId)
                const productDoc = await getDoc(productDocRef)
                if (productDoc.exists()) {
                    setProduct(productDoc.data() as IProduct)
                }
                setIsLoadingProduct(false)
            }

        })()
    }, [props.transaction.productId])

    return (
        <div className="z-50 fixed top-0 left-0 w-full h-full backdrop-blur-md bg-white/30 flex items-center justify-center">
            <div className="relative bg-gray-50 dark:bg-gray-700 px-10 py-10 w-full h-full md:w-[500px] md:h-[300px] rounded-none md:rounded-lg flex flex-col items-center justify-start gap-2">
                <div className="absolute top-3 right-3">
                    <button onClick={props.onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 w-7 fill-red-700">
                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                        </svg>

                    </button>

                </div>
                <img
                    src={require("../../../../assets/images/brand/logo-compact.png")}
                    className="w-16 mb-3"
                    alt="autopilot pro"
                />
                <RenderDatum title="Transaction ID" value={props.transaction.id} />
                <RenderDatum title="For" value={props.transaction.scope.toUpperCase()} />
                <RenderDatum title="Amount" value={new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(props.transaction.amount)} />
                <RenderDatum title="Status" value={props.transaction.status.toUpperCase()} />
                {
                    props.transaction.status === "failed" ? <RenderDatum title="Reason" value={props.transaction.errorMsg || ""} /> : null
                }
                {
                    isLoadingProduct ? "..." : product ? <RenderDatum title="Product" value={product?.title || ""} /> : null
                }

                {/* <button
                            onClick={props.options.actionClick}
                            className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                        >
                            <span className="relative text-base font-semibold text-white">{props.options.actionText ? props.options.actionText : "Done"}</span>
                        </button> */}
            </div>
        </div>
    )
}

export default TransactionListItemDetailView