

import { useEffect, useState } from "react";
import { db } from "../../../firebase/client"
import { collection, query, orderBy, startAfter, limit, getDocs } from "firebase/firestore";
import TransactionListSkeletonView from "../../dashboard-views/transaction/components/TransactionListSkeletonView";
import TransactionListView from "../../dashboard-views/transaction/components/TransactionListView";


const ManageTransactionsView = () => {

    const [transactions, setTransactions] = useState<ITransaction[]>([])
    const [lastVisibleTransaction, setLastVisibleTransaction] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    useEffect(() => {
        (async () => {
           
                setIsLoading(true)
                const first = query(collection(db, "transactions"), orderBy("createdAt", "desc"), limit(10));
                const documentSnapshots = await getDocs(first);
                documentSnapshots.forEach((doc) => {
                    setTransactions(prev => [...prev, doc.data() as ITransaction])
                })
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                setLastVisibleTransaction(lastVisible)
                setIsLoading(false)
         
        })()
    }, [])

    return (
        <>
            <h1
                className="text-black dark:text-gray-600 text-2xl md:text-4xl font-bold my-8"
            >
                Transactions
            </h1>
            <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-10  gap-2">
                <div className="col-span-1">
                </div>
                <div className="col-span-8">
                    {
                        isLoading ? <TransactionListSkeletonView /> : <TransactionListView transactions={transactions} />
                    }
                    {
                        lastVisibleTransaction && <button
                            onClick={async () => {
                                setIsLoadingMore(true)
                                const more = query(collection(db, "transactions"), orderBy("createdAt", "desc"), startAfter(lastVisibleTransaction), limit(10));
                                const documentSnapshots = await getDocs(more);
                                documentSnapshots.forEach((doc) => {
                                    setTransactions(prev => [...prev, doc.data() as ITransaction])
                                })
                                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                                setLastVisibleTransaction(lastVisible)
                                setIsLoadingMore(false)
                            }}
                            className="mt-10 relative flex h-10 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                            disabled={isLoadingMore}
                        >
                            {
                                isLoadingMore ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 animate-spin w-5 stroke-white dark:stroke-dark">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                                    : <span className="relative text-base font-semibold text-white dark:text-dark"
                                    >Show more</span>
                            }

                        </button>
                    }
                </div>
                <div className="col-span-1"></div>
            </div>
        </>
    )
}

export default ManageTransactionsView