import { getCountFromServer, Query } from "firebase/firestore"
import { FC, ReactNode, useEffect, useState } from "react"

interface Props {
    query: Query
    title: string
    icon: ReactNode
}
const OverviewCountView: FC<Props> = (props) => {
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const snapshot = await getCountFromServer(props.query);
                setCount(snapshot.data().count)
                setIsLoading(false)
            } catch (error: any) {
                console.log(error.message);

            }
        })()
    }, [props.query])

    return (
        <>
            {
                isLoading ? <div className="animate-pulse h-[100px] bg-slate-700 rounded"></div>
                    : <div className="flex flex-col items-start justify-between p-4  rounded col-span-1 w-[300px] h-[100px] group relative bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">

                        <div className="flex items-center gap-2">
                            {props.icon}
                            <span className="text-sm text-center font-thin text-gray-700 dark:text-white text-ellipsis overflow-hidden grow">
                                {props.title}
                            </span>
                        </div>

                        <span className="text-2xl text-center font-semibold text-gray-700 dark:text-white">
                            {new Intl.NumberFormat("en-GB").format(count)}
                        </span>

                    </div>
            }
        </>
    )
}

export default OverviewCountView