
import { db } from "../../../firebase/client"
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react"
import MarketplaceListSkeletonView from "./components/MarketplaceListSkeletonView";
import MarketplaceListView from "./components/MarketplaceListView";


const MarketplaceShortcutView = () => {

    const [products, setProducts] = useState<IProduct[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const first = query(collection(db, "products"), orderBy("createdAt"), limit(4));
            const documentSnapshots = await getDocs(first);
            documentSnapshots.forEach((doc) => {
                setProducts(prev => [...prev, doc.data() as IProduct])
            })
            setIsLoading(false)
        })()
    }, [])

  return (
    <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between">
                <h4 className="text-xl text-left font-semibold text-gray-700 dark:text-white">
                    Products
                </h4>

                <a href="/dashboard/marketplace"  className="relative text-base font-semibold text-primary">View all</a>
            </div>

            {
                isLoading ? <MarketplaceListSkeletonView isShorcut /> : <MarketplaceListView  isShorcut products={products} />
            }

        </div>
  )
}

export default MarketplaceShortcutView