import { useEffect, useState } from "react"
import { useUserAuth } from "../../../../../context/userAuthContext"
import ModalAlert, { ModalAlertProps } from "../../../../../components/interaction/ModalAlert"
import { useNavigate } from "react-router-dom"

const WalletView = () => {
    const { sessionUser, isLoadingUser } = useUserAuth()
    const navigate = useNavigate()
    const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
        options: {
            visible: false,
            actionClick: () => { },
            severity: "success"
        }
    })

    useEffect(() => {
        const topupAlert = localStorage.getItem("showntopupAlert")
        if (topupAlert === null) {
            setModalAlert((prev) => {
                return {
                    options: {
                        ...prev.options,
                        visible: true,
                        message: "Before you purchase any package you need to top up your wallet balance",
                        severity: "info",
                        actionText: "Top up wallet",
                        actionClick: () => {
                            localStorage.setItem("showntopupAlert", "true")
                            navigate("/dashboard/top-up")
                        }
                    }
                }
            })
        }


    }, [navigate])

    return (
        <>
            <ModalAlert options={modalAlert.options} />
            {
                isLoadingUser ? <div className="animate-pulse h-[100px] bg-slate-700 rounded"></div>
                    : <div className="flex flex-col items-start justify-between p-4  rounded col-span-1 lg:w-[300px] w-full h-[100px] group relative bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">

                        <div className="flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-primary">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3" />
                            </svg>
                            <span className="text-sm text-center font-thin text-gray-700 dark:text-white text-ellipsis overflow-hidden grow">
                                Balance
                            </span>
                        </div>

                        <span className="text-2xl text-center font-semibold text-gray-700 dark:text-white">
                            {new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(sessionUser?.balance || 0)}
                        </span>
                        <div className="absolute bottom-3 right-3">
                            <a
                                href="/dashboard/top-up"
                                className="relative flex h-7 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                            >
                                <span className="relative text-base font-semibold text-white dark:text-dark"
                                >Top up</span>
                            </a>
                        </div>
                    </div>
            }
        </>
    )
}

export default WalletView