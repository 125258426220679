import ManageToolsView from "../ManageToolsView"
import { ManageToolsProvider } from "./ManageToolsContext"


const ManageToolsContainerView = () => {
    return (
        <ManageToolsProvider>
            <ManageToolsView />
        </ManageToolsProvider>
    )
}

export default ManageToolsContainerView