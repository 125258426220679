// import { useState } from "react";
// import { auth } from "../../../firebase/client";
// import { CreditCard, PaymentForm, } from 'react-square-web-payments-sdk';
// import ModalAlert, { ModalAlertProps } from "../../../components/interaction/ModalAlert";
// import { useNavigate } from "react-router-dom";


const TopupView = () => {


    // const [amount, setAmount] = useState<number>(20000);
    // const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
    //     options: {
    //         visible: false,
    //         actionClick: () => { },
    //         severity: "success"
    //     }
    // })


    // const appId = `${process.env.REACT_APP_SQUARE_APPLICATION_ID}`
    // const locationId = `${process.env.REACT_APP_SQUARE_LOCATION_ID}`

    // const navigate = useNavigate()


    return (
        <>
            {/* <ModalAlert options={modalAlert.options} /> */}
            <h1
                className="text-black dark:text-gray-600 text-2xl md:text-4xl font-bold my-8"
            >
                Top up
            </h1>

            <div className="w-full h-[70vh]  flex flex-col items-center justify-center ">
                <div className="bg-white dark:bg-gray-800 shadow-md rounded lg:px-8 px-2 pt-6 pb-8 mb-4 lg:w-[500px] w-full h-auto">
                    <div className="bg-orange-50 p-2 rounded">
                        <p className="text-black text-sm font-thin">We are currently facing issues with the payment gateway. There is no cause for alarm we are currently working on it. If you want to make payment contact us.</p>
                        <p>Chat with us: <a href="https://tawk.to/chat/67202b914304e3196ad9c721/1ibaqkbsu" target="_blank" rel="noopener noreferrer" className="underline decoration-primary ">Link</a></p>
                        <p>Email: <a href="mailto:support@theautopilotpro.com" className="underline decoration-primary ">support@theautopilotpro.com</a></p>
                    </div>
                    {/* <PaymentForm
                        applicationId={appId}
                        locationId={locationId}
                        cardTokenizeResponseReceived={async (token) => {

                            try {
                                const response = await fetch(`${process.env.REACT_APP_API}/square/createPayment`, {
                                    method: "POST",
                                    headers: {
                                        "Content-type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        locationId,
                                        sourceId: token.token,
                                        amount: amount,
                                        userId: auth.currentUser?.uid,
                                    }),
                                });




                                if (response.status === 200) {
                                    setModalAlert((prev) => {
                                        return {
                                            options: {
                                                ...prev.options,
                                                visible: true,
                                                message: "Top up successful",
                                                severity: "success",
                                                actionClick: () => {
                                                    navigate("/dashboard/home");
                                                },
                                                actionText: "Go to dashboard"
                                            }
                                        }
                                    })
                                } else {

                                    setModalAlert((prev) => {
                                        return {
                                            options: {
                                                ...prev.options,
                                                visible: true,
                                                message: "Oops! try again",
                                                severity: "failed",
                                                actionClick: () => {

                                                    setModalAlert((prev) => {
                                                        return {
                                                            options: {
                                                                ...prev.options,
                                                                visible: false
                                                            }
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    })
                                }
                            } catch (error: any) {

                                setModalAlert((prev) => {
                                    return {
                                        options: {
                                            ...prev.options,
                                            visible: true,
                                            message: error.message || "Oops! try again",
                                            severity: "failed",
                                            actionClick: () => {

                                                setModalAlert((prev) => {
                                                    return {
                                                        options: {
                                                            ...prev.options,
                                                            visible: false
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    }
                                })
                            }
                        }}

                    >
                        <div className="my-5">
                            <label htmlFor="amount" className="text-gray-900 dark:text-white">Enter Amount</label>
                            <input
                                value={new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(amount / 100)}
                                onChange={(e) => {
                                    let val = e.target.value.replace(/[^0-9]/g, '') as string
                                    const _amount = parseInt(val) || 0;
                                    setAmount(_amount)
                                }}
                                id="amount" type="text" placeholder="Amount in GBP" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" />
                        </div>
                        <CreditCard />


                    </PaymentForm> */}
                </div>

            </div>


            {/* <div className="w-full h-[70vh]  flex flex-col items-center justify-center ">
                <div className="bg-white dark:bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[500px] h-auto">
                    <form id="payment-form">
                        <div className="my-5">
                            <label html-for="amount" className="text-gray-900 dark:text-white">Enter Amount</label>
                            <input id="amount" type="number" placeholder="Amount in GBP" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" />
                        </div>
                        <div id="card-container"></div>
                        <button
                            id="card-button"
                            className="relative flex h-10 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                        >
                            <span id="card-button-text" className="relative text-base font-semibold text-white dark:text-dark"
                            >Pay</span>
                        </button>
                    </form>

                </div>

                <div className="fixed top-0 left-0 w-full h-full backdrop-blur-md bg-white/30 hidden items-center justify-center" id="alert">

                    <div className="bg-gray-50 dark:bg-gray-700 px-10 py-10 rounded-lg flex flex-col items-center justify-center gap-5">
                        <img src="/images/interaction/check-mark.png" className="h-20 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" id="alert-img" />
                        <span className="text-gray-900 dark:text-gray-300 text-2xl font-bold" id="alert-title"> </span>
                        <span className="text-gray-900 dark:text-gray-300 text-sm font-thin text-center" id="alert-message"></span>

                        <button
                            id="alert-btn"
                            className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                        >
                            <span className="relative text-base font-semibold text-white">Done</span>
                        </button>
                    </div>

                </div>
            </div> */}
        </>
    )
}

export default TopupView