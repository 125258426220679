import { type FC } from "react"
import { useNavigate } from "react-router-dom"

interface MarketplaceListItemViewProps {
    product: IProduct
    isShorcut?: boolean
}

const MarketplaceListItemView: FC<MarketplaceListItemViewProps> = (props) => {
    const navigate = useNavigate()

    return (
        <>
            <div onClick={() => { navigate(`/dashboard/marketplace/${props.product.id}`) }} className="relative flex  gap-2 items-center  rounded col-span-1 min-h-[50px] group  bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 cursor-pointer">


                <img src={props.product?.imgUrl} alt={props.product?.title} className={`${props.isShorcut ? "w-16" : "lg:w-32 w-16"} aspect-square object-cover`} />
                <div className={`${props.isShorcut ? "min-h-[60px]" : "lg:min-h-[100px] min-h-[60px]"} flex flex-col justify-start items-start grow `}>
                    <h2 className={`${props.isShorcut ? "text-sm w-[50%]" : "lg:text-xl lg:w-full text-sm w-[50%]"}  text-left font-semibold text-gray-700 dark:text-white truncate `}>
                        {props.product.title}
                    </h2>
                    <p className={`lg:text-sm text-xs font-thin text-gray-900 dark:text-gray-100 truncate ${props.isShorcut ? "w-[70%]":"lg:w-full w-[90%]"}`}>
                        {props.product.caption}
                    </p>


                </div>
                <div className={`${props.isShorcut ? "top-0 ":"lg:top-4 top-0"} absolute right-4`}>
                    <span className={`${props.isShorcut ? "lg:text-sm text-xs" : "lg:text-xl text-xs"} text-center font-semibold text-gray-700 dark:text-white`}>
                        {new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(props.product.price)}
                    </span>
                </div>
            </div>
        </>
    )
}

export default MarketplaceListItemView