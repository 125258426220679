import { doc, getDoc } from "firebase/firestore"
import { FC, useEffect, useState } from "react"
import { db } from "../../../../firebase/client"
import { useManageTools } from "./ManageToolsContext"
import { useNavigate } from "react-router-dom"

interface Props {
    tool: ITool
}
const ManageToolsListItemView: FC<Props> = (props) => {
    const [product, setProduct] = useState<IProduct | undefined>(undefined)
    const [user, setUser] = useState<IUser | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    const { selectedTool } = useManageTools()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const productDocRef = doc(db, "products", props.tool.productId)
            const productDoc = await getDoc(productDocRef)
            if (productDoc.exists()) {
                setProduct(productDoc.data() as IProduct)
            }

            const userDocRef = doc(db, "users", props.tool.userId)
            const userDoc = await getDoc(userDocRef)
            if (userDoc.exists()) {
                setUser(userDoc.data() as IUser)
            }


            setIsLoading(false)
        })()
    }, [props.tool.productId, props.tool.userId])


    if (isLoading) {
        return <div className="animate-pulse h-9 bg-slate-700 rounded">

        </div>
    }
    return (
        <div onClick={() => {
            navigate(`/manage/tools?selected-tool-id=${props.tool.id}`)
        }} className={`relative p-2 rounded flex flex-col gap-1  min-h-[50px] group  ${selectedTool?.id === props.tool.id ? "bg-primary" : "bg-gray-50 dark:bg-gray-800"} transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 cursor-pointer`}>
            <span className="text-black dark:text-white font-thin text-sm">{user?.name} ({user?.email})</span>
            <span className="text-black dark:text-white font-light text-xs">{product?.title}</span>
            <div className="absolute top-0 right-1">
                <span className="text-black dark:text-white font-thin text-xs text-right w-full">{new Date(props.tool.createdAt?.seconds * 1000).toDateString()}</span>
            </div>
        </div>
    )
}

export default ManageToolsListItemView