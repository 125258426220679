import type { FC } from "react"


interface SubmitButtonProps {
    disabled?: boolean
    loading: boolean
    btnText?: string
    onClick: () => void
}
const SubmitButton: FC<SubmitButtonProps> = (props) => {
    return (
        <button
            onClick={() => {
                props.onClick()
            }}
            disabled={props.disabled ? props.disabled : false}

            className={`relative flex h-10 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full ${props.disabled || props.loading ? "before:bg-gray-300" : "before:bg-primary"}  before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max`}>
            {
                props.loading ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 animate-spin w-5 stroke-white dark:stroke-dark">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg> : <span className="relative text-base font-semibold text-white dark:text-dark"
                >{props.btnText ? props.btnText : "Submit"}</span>
            }
        </button>
    )
}

export default SubmitButton