import { Field } from "formik"
import type { FC } from "react"


interface EmailFieldInputProps {
    name: string
    label: string
    placeholder?:string
}
const EmailFieldInput: FC<EmailFieldInputProps> = (props) => {
    return (
        <Field name={props.name}>
            {({ field, _, meta }:any) => (
                <div>
                    <label htmlFor={props.name} className="text-gray-900 dark:text-white">{props.label}</label>
                    <input id={props.name} type="email" {...field} placeholder={props.placeholder ? props.placeholder :  `Enter ${props.name}`} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" />
                    {meta.touched &&
                        meta.error && <div className="text-red-700">{meta.error}</div>}
                </div>
            )}
        </Field>
    )
}

export default EmailFieldInput