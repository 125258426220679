import { useUserAuth } from "../../../context/userAuthContext"
import MarketplaceShortcutView from "../marketplace/MarketplaceShortcutView"
import ReferralView from "../referral/ReferralView"
import RecentTransactionsView from "../transaction/RecentTransactionsView"
import WalletView from "./components/wallet/WalletView"

const DashboardView = () => {
  const { sessionUser } = useUserAuth()
  return (
    <>
      <h1 className="text-gray-700 dark:text-white lg:mt-0 mt-8">
        Welcome {sessionUser?.name}
      </h1>
      <div className="w-full  flex flex-col md:flex-row items-center gap-2">
        <WalletView />
        <ReferralView />
      </div>

      <iframe className="w-full min-h-[150px] md:min-h-[500px] aspect-video  my-5" src="https://www.youtube.com/embed/AtdT3ZtS57M?si=p7tUxkPn-iXcV90X" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-12 gap-6">
        <div className="col-span-4 flex flex-col gap-3">

          <div className=" p-4  rounded col-span-1 min-h-[150px] group relative bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
            <img src={require("../../../assets/images/products/pngkey.com-special-offer-png-icon-2577960.png")} alt="offer" className="absolute top-0 right-0 w-20 object-cover" />
            <span className="text-xl font-semibold text-gray-700 dark:text-white">
              Full Automation
            </span>
            <p className="text-gray-600 dark:text-gray-300">Features</p>
            <ul className="text-gray-600 dark:text-gray-300 list-disc mx-1 ">
              <li>1,200 ready to purchase email leads!!!</li>
              <li>365 days autoresponder Messages (100 every three months)</li>
              <li>Leads Capture Page ( On Request)</li>
              <li>Free Website + Hosting of your choice ( IPS, Legacy, Lepo, Simply Passive, UBC, others)</li>
              <li>+ more</li>
            </ul>
            <a
              href="/dashboard/marketplace/pIRkIixsTrT5R96WLsrp"
              className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-base font-semibold text-white"
              >View</span>
            </a>
          </div>


        </div>
        <div className="col-span-4">
          <RecentTransactionsView />
        </div>
        <div className="col-span-4">
          <MarketplaceShortcutView />
        </div>
      </div>
    </>
  )
}

export default DashboardView