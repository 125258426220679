import { useEffect, useState } from "react"
import { useManageTools } from "./ManageToolsContext"
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "../../../../firebase/client"
import SubmitButton from "../../../../components/forms/inputs/SubmitButton"
import { useNavigate } from "react-router-dom"
import ManageToolsResourcesHistoryView from "./ManageToolsResourcesHistoryView"

const ManageToolsResourcesView = () => {
  const { selectedTool, setSelectedTool } = useManageTools()

  const [product, setProduct] = useState<IProduct | undefined>(undefined)
  const [user, setUser] = useState<IUser | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const [isProcessing, setIsProcessing] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      if (selectedTool) {
        setIsLoading(true)
        const productDocRef = doc(db, "products", selectedTool?.productId)
        const productDoc = await getDoc(productDocRef)
        if (productDoc.exists()) {
          setProduct(productDoc.data() as IProduct)
        }

        const userDocRef = doc(db, "users", selectedTool?.userId)
        const userDoc = await getDoc(userDocRef)
        if (userDoc.exists()) {
          setUser(userDoc.data() as IUser)
        }


        setIsLoading(false)
      }

    })()
  }, [selectedTool])


  if (isLoading) {
    return <div className="animate-pulse h-9 bg-slate-700 rounded">

    </div>
  }

  if (selectedTool === undefined) {
    return <div className="text-black dark:text-white text-2xl flex flex-col items-center justify-center h-[200px] group  bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
      Select from the list to show resources
    </div>
  }
  return (
    <div className="p-2 rounded flex flex-col gap-1  min-h-[600px] group  bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 shadow-primary">
      <div className="flex items-center gap-2 mb-4">
        <button onClick={() => { setSelectedTool(undefined) }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-red-500">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>

        <div className="flex flex-col grow">
          <span className="text-black dark:text-white font-thin text-sm">{user?.name} ({user?.email})</span>
          <span className="text-black dark:text-white font-light text-xs">{product?.title}</span>
        </div>
      </div>

      {
        selectedTool.status === "pending" ?
          <div className="flex flex-col gap-4 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-10 stroke-orange-300">
              <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>

            <span className="text-black dark:text-gray-200 text-sm font-thin">Are you ready to work on this?</span>
            <SubmitButton loading={isProcessing} disabled={isProcessing} onClick={async () => {
              try {
                setIsProcessing(true)
                const toolDocRef = doc(db, "tools", selectedTool.id)
                await updateDoc(toolDocRef, {
                  status: "in-progress"
                })
                setTimeout(() => {
                  navigate(`/manage/tools?tab-index=1&selected-tool-id=${selectedTool.id}`)
                }, 2000);
                setIsProcessing(false)
              } catch (error) {

              }
            }} btnText="Start" />
          </div>
          : null
      }

      {
        selectedTool.status === "in-progress" ?
          <>
            <ManageToolsResourcesHistoryView />
          </> : <></>
      }
    </div>
  )
}

export default ManageToolsResourcesView