import { createContext, ReactNode, useContext, useState } from "react";


interface IManageUsersContext {
    selectedUser?: IUser
    setSelectedUser: (user?: IUser|undefined) => void
}

const ManageUsersContext = createContext<IManageUsersContext | undefined>(undefined);

export const useManageUsers = () => {
    const context = useContext(ManageUsersContext);
    if (!context) {
        throw new Error("useManageUsers must be used within a ManageUsersProvider");
    }
    return context;
};


type ManageUsersProviderProps = {
    children: ReactNode;
};


export const ManageUsersProvider = ({ children }: ManageUsersProviderProps) => {
    const [selectedUser, setselectedUser] = useState<IUser | undefined>(undefined)

    const setSelectedUser = (user?: IUser|undefined) => {
        setselectedUser(user)
    }
    return (
        <ManageUsersContext.Provider
            value={{
                selectedUser,
                setSelectedUser
            }} >
            {children}
        </ManageUsersContext.Provider>
    )
}