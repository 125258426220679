import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useUserAuth } from "../../../context/userAuthContext"
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore"
import { db } from "../../../firebase/client"
import ToolsResourcesHistoryView from "./components/ToolsResourcesHistoryView"

const ToolManageView = () => {
    const { toolId } = useParams()

    const navigate = useNavigate()

    const { sessionUser } = useUserAuth()

    const [tool, setTool] = useState<ITool | undefined>(undefined)
    const [product, setProduct] = useState<IProduct | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    const [needsSetup, setNeedsSetup] = useState(false)


    useEffect(() => {
        (async () => {
            if (toolId) {
                const toolQueryRef = query(collection(db, "tools"), where("id", "==", toolId), where("userId", "==", sessionUser?.uid))
                const toolDocs = await getDocs(toolQueryRef)

                if (toolDocs.size === 1) {
                    setTool(toolDocs.docs[0].data() as ITool)
                }
            }
        })()
    }, [toolId, sessionUser?.uid])

    useEffect(() => {
        (async () => {
            if (tool) {
                setIsLoading(true)
                const productDocRef = doc(db, "products", tool.productId)
                const productDoc = await getDoc(productDocRef)
                if (productDoc.exists()) {
                    setProduct(productDoc.data() as IProduct)
                }
                setIsLoading(false)
            }

        })()
    }, [tool])

    useEffect(() => {
        if (product?.setupForm && tool?.setup === undefined) {
            setNeedsSetup(true)
        }
    }, [product, tool])


    if (isLoading) {
        return <div className="animate-pulse h-16 bg-slate-700 rounded">

        </div>
    }

    return (
        <div>
            <div className="flex items-center gap-2 flex-wrap mt-10">
                <img src={product?.imgUrl} alt={product?.title} className="w-20 aspect-square object-cover" />

                <h1
                    className="text-black dark:text-gray-300 text-2xl md:text-4xl font-bold mt-8"
                >
                    {product?.title}
                </h1>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
                <span className="text-black dark:text-white font-thin underline decoration-primary underline-offset-2 cursor-pointer" onClick={() => { navigate("/dashboard/my-tools") }}>My Tools</span>
                <span className="text-black dark:text-white font-thin">&#62;</span>
                <span className="text-black dark:text-gray-200 font-thin"> {product?.title}</span>
            </div>

            <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-2 mt-10 gap-3">
                <div>
                    <div className="mb-8 flex flex-col  p-4  rounded min-h-[50px] group relative bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">

                        <p className="text-gray-600 dark:text-gray-300 text-base font-thin">
                            {product?.caption}
                        </p>
                    </div>

                    <div className="my-8 flex flex-col  p-4  rounded min-h-[200px] group relative bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
                        <h3
                            className="text-black dark:text-gray-600 text-xl md:text-2xl font-bold"
                        >
                            Features
                        </h3>
                        <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                            {
                                product?.features.map((feature, index) => <li key={index + 1}>{feature}</li>)
                            }

                        </ul>
                    </div>
                    {
                        needsSetup ? <>
                            <div className="my-8 flex flex-col  p-4  rounded min-h-[100px] group relative bg-red-100 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
                                <h3
                                    className="text-red-700 text-xl md:text-2xl font-bold flex items-center gap-2"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-red-700">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                    </svg>
                                    Needs setup
                                </h3>

                                <p className="text-red-800 text-sm">
                                    Click <a href={`/dashboard/my-tools/setup/${tool?.id}`} className="underline decoration-primary underline-offset-1 text-primary">here</a> to complete setup.
                                </p>
                            </div>

                        </> : <></>
                    }


                </div>
                <div>
                    <div className="flex flex-col  p-4  rounded min-h-[200px] group relative bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
                        <h3
                            className="text-black dark:text-gray-600 text-xl md:text-2xl font-bold mb-4"
                        >
                            Resources / Links
                        </h3>
                        {
                            tool?.status === "pending" ? <>

                                <div className="p-1 w-fit bg-orange-100 text-orange-700 rounded font-thin text-sm flex flex-col gap-2 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-10 stroke-orange-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                    </svg>
                                    Your Setup is underway, we wil reach out to you if there are any issues, you can also contact us if you have any request or questions
                                </div>
                            </> : <></>
                        }
                        {
                            tool?.status === "in-progress" && toolId ? <ToolsResourcesHistoryView toolId={toolId} /> : <></>
                        }

                    </div>



                </div>

            </div>
        </div>
    )
}

export default ToolManageView
