import ContainerView from "../landing-layout/components/ContainerView"

const TermsOfUseView = () => {
    return (
        <main
            className="space-y-40 mb-40"
        >
            <ContainerView>
                <div className="relative pt-36 ml-auto">
                    <div className="lg:w-2/3 text-center mx-auto mb-10">
                        <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"> <span className="text-primary dark:text-white">TERMS AND CONDITIONS</span></h1>
                    </div>
                </div>
                <p className="text-gray-600 dark:text-gray-300">Effective date: August 01, 2019</p>

                <p className="text-gray-600 dark:text-gray-300">Welcome to The Autopilot Pro! By accessing or using our website and services, you agree to
                    comply with and be bound by these T erms of Use. Please read these terms carefully before
                    using our platform.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Acceptance of Terms
                </h3>
                <p className="text-gray-600 dark:text-gray-300">By using The Autopilot Pro website and services, you agree to be bound by these T erms of Use,
                    as well as our Privacy Policy. If you do not agree to these terms, please do not use our platform.</p>
                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Use of the Site
                </h3>
                <p className="text-gray-600 dark:text-gray-300">You may use The Autopilot Pro for lawful purposes only. You agree not to:</p>
                <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                    <li>Use our site or services for any illegal activity or to violate any laws.</li>
                    <li>Attempt to gain unauthorized access to any part of our platform, accounts, or
                        systems.</li>
                    <li>Use any automated system, software, or other means to extract data from our
                        site (e.g., scraping).</li>
                    <li>Transmit any malicious code or viruses that could damage or interfere with our
                        platform.</li>
                </ul>
                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Account Registration and Security
                </h3>
                <p className="text-gray-600 dark:text-gray-300">When creating an account with The Autopilot Pro, you agree to provide accurate and up-to-date
                    information. You are responsible for maintaining the confidentiality of your account credentials
                    and for all activities that occur under your account. If you suspect unauthorized use of your
                    account, please notify us immediately at <a href="mailto:support@theautopilotpro.com" className="underline decoration-primary ">support@theautopilotpro.com</a></p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Payment and Subscription
                </h3>
                <p className="text-gray-600 dark:text-gray-300">By purchasing products or services from The Autopilot Pro, you agree to our pricing and
                    payment terms. All sales of digital products are final, and refunds are only granted under
                    specific exceptions outlined in our <a href="/refund-policy" className="underline decoration-primary ">No Refund Policy</a>.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Intellectual Property
                </h3>
                <p className="text-gray-600 dark:text-gray-300">All content on The Autopilot Pro, including but not limited to text, graphics, logos, images, and
                    software, is the property of The Autopilot Pro or its content suppliers and is protected by
                    copyright, trademark, and other intellectual property laws. You may not reproduce, distribute,
                    modify, or create derivative works from our content without prior written permission.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    User-Generated Content
                </h3>
                <p className="text-gray-600 dark:text-gray-300">You may have the opportunity to submit comments, reviews, or other content on our platform.
                    By submitting content, you grant The Autopilot Pro a non-exclusive, royalty-free, perpetual, and
                    worldwide license to use, reproduce, and distribute your content in any media.</p>
                <p className="text-gray-600 dark:text-gray-300">You are solely responsible for the content you submit and agree not to post anything that is
                    illegal, offensive, or infringes on the rights of others.</p>
                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Disclaimer of Warranties
                </h3>
                <p className="text-gray-600 dark:text-gray-300">The Autopilot Pro provides its services on an <b>“as is”</b> and <b>“as available”</b> basis. We make no
                    representations or warranties of any kind, express or implied, regarding the operation of the site
                    or the information, content, or services provided.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Limitation of Liability
                </h3>
                <p className="text-gray-600 dark:text-gray-300">To the fullest extent permitted by law, The Autopilot Pro will not be liable for any damages,
                    including but not limited to direct, indirect, incidental, or consequential damages arising from the
                    use or inability to use our site or services.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Termination
                </h3>
                <p className="text-gray-600 dark:text-gray-300">We reserve the right to suspend or terminate your account and access to our platform at our
                    discretion, without notice, for conduct that violates these Terms of Use or is harmful to our
                    interests or the interests of others.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Governing Law
                </h3>
                <p className="text-gray-600 dark:text-gray-300">These Terms of Use are governed by and construed in accordance with the laws of the UK
                    without regard to its conflict of law principles.</p>
                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Changes to Terms
                </h3>
                <p className="text-gray-600 dark:text-gray-300">We reserve the right to update or modify these T erms of Use at any time. When we make
                    changes, we will update the “Effective Date” at the top of this page and notify users of significant
                    changes via email or our website.</p>
                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Contact Us
                </h3>
                <p className="text-gray-600 dark:text-gray-300">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:.</p>
                <p className="text-gray-600 dark:text-gray-300">Email: <a href="mailto:support@theautopilotpro.com" className="underline decoration-primary ">support@theautopilotpro.com</a></p>


            </ContainerView>



        </main>
    )
}

export default TermsOfUseView