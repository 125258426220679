import ContainerView from "../landing-layout/components/ContainerView"

const ContactView = () => {
  return (
    <main
      className="space-y-40 mb-40"
    >
      <ContainerView>
        <div className="relative pt-36 ml-auto">
          <div className="lg:w-2/3 text-center mx-auto mb-10">
            <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"> <span className="text-primary dark:text-white">Contact us.</span></h1>
            <p className="mt-8 text-gray-700 dark:text-gray-300">We’re here to help! If you have any questions, concerns, or feedback, please feel free to reach out to us through the methods below. Our team is committed to providing excellent support and ensuring you have the best experience with The Autopilot Pro.</p>
          </div>
        </div>
        <div className="md:w-2/3 lg:w-1/2">
          <h2 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
            Customer Support
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            For assistance with your account, product inquiries, or troubleshooting, our customer support team is available during business hours.
          </p>
          <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
            <li>Email: <a href="mailto:support@theautopilotpro.com" className="underline decoration-primary ">support@theautopilotpro.com</a></li>
            <li>Business Hours: Monday to Friday, 9:00 AM – 6:00 PM [UTC]</li>
          </ul>

          <p className="text-gray-600 dark:text-gray-300">You can also chat with us now via the link below </p>
          
          <a href="https://tawk.to/chat/67202b914304e3196ad9c721/1ibaqkbsu" target="_blank" rel="noopener noreferrer" className="underline decoration-primary ">
          <p className="text-gray-600 dark:text-gray-300">Link </p>
          </a>
          <h2 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
            General Inquiries
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            For general information about The Autopilot Pro, partnership opportunities, or media inquiries:
          </p>
          <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
            <li>Email: <a href="mailto:support@theautopilotpro.com" className="underline decoration-primary ">support@theautopilotpro.com</a></li>
          </ul>
          <p className="text-gray-600 dark:text-gray-300">
            We look forward to hearing from you and assisting with your digital marketing journey!
          </p>
        </div>
      </ContainerView>



    </main>
  )
}

export default ContactView