
import { Outlet } from "react-router-dom";
import "../../dashboard-views/dashboard-layout/DashboardLayout.css"
import AdminSidebar from "./components/AdminSidebar";


const AdminLayoutView = () => {


  return (
    <div className="flex flex-col lg:flex-row relative">
      <AdminSidebar />
      <div className="w-full flex flex-col h-screen overflow-y-hidden">
        <div className="w-full overflow-x-hidden border-t flex flex-col">
          <main className="w-full flex-grow p-6">
            <Outlet />
          </main>
        </div>
      </div>

    </div>
  )
}

export default AdminLayoutView