import ManageUsersView from "../ManageUsersView"
import { ManageUsersProvider } from "./ManageUsersContext"

const ManageUsersContainerView = () => {
    return (
        <ManageUsersProvider>
            <ManageUsersView />
        </ManageUsersProvider>
    )
}

export default ManageUsersContainerView