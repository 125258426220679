import { Outlet } from "react-router-dom";
import "./DashboardLayout.css"
import DashboardSidebar from "./components/DashboardSidebar";


const DashboardLayoutView = () => {


  return (
    <div className="flex">
      <DashboardSidebar />
      <div className="w-full flex flex-col h-screen overflow-y-hidden">
        <div className="w-full overflow-x-hidden border-t flex flex-col">
          <main className="w-full flex-grow lg:p-6 p-2">
            <Outlet />
          </main>
        </div>
      </div>

    </div>
  )
}

export default DashboardLayoutView