import ContainerView from "../landing-layout/components/ContainerView"

const RefundPolicyView = () => {
  return (
    <main
      className="space-y-40 mb-40"
    >
      <ContainerView>
        <div className="relative pt-36 ml-auto">
          <div className="lg:w-2/3 text-center mx-auto mb-10">
            <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"> <span className="text-primary dark:text-white">Refund policy            </span></h1>
          </div>
        </div>

        <h2 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
          No Refund Policy
        </h2>
        <p className="text-gray-600 dark:text-gray-300">
          At The Autopilot Pro, we are committed to providing high-quality digital marketing tools, training, and resources to help you succeed. Before making a purchase, we encourage you to thoroughly review the product descriptions and features to ensure it meets your needs.
        </p>
        <p className="text-gray-600 dark:text-gray-300">
          Due to the nature of our digital products and services, all sales are final, and we do not offer refunds or exchanges under any circumstances. This includes but is not limited to:
        </p>
        <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
          <li>Digital training programs</li>
          <li>Email leads</li>
          <li>Software tools</li>
          <li>Any downloadable resources</li>
        </ul>
        <h2 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
          Exceptions to the Policy
        </h2>
        <p className="text-gray-600 dark:text-gray-300">
          We understand that unique situations may arise, and we are willing to consider requests for exceptions in the following cases:
        </p>
        <ul className="text-gray-600 dark:text-gray-300 list-decimal mx-8 ">
          <li>Duplicate Purchase: If you accidentally purchase the same product twice, we will gladly issue a refund for the duplicate transaction.</li>
          <li>Non-Delivery of Service: If you encounter technical issues that prevent you from accessing a purchased product, we will work to resolve the issue promptly. If we are unable to do so within a reasonable time frame, we may offer a refund.</li>
        </ul>
        <h2 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
          How to Request an Exception
        </h2>
        <p className="text-gray-600 dark:text-gray-300">If you believe you qualify for an exception to our no-refund policy, please contact us at support@theautopilotpro.com In your request, include the following:</p>
        <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
          <li>Proof of purchase</li>
          <li>Details of the issue</li>
          <li>Any relevant screenshots or documentation</li>
        </ul>
        <p className="text-gray-600 dark:text-gray-300">Our team will review your request and respond within 7-10 business days.</p>
        <p className="text-gray-600 dark:text-gray-300">We appreciate your understanding and are dedicated to supporting you on your digital marketing journey.</p>


      </ContainerView>



    </main>
  )
}

export default RefundPolicyView