import ContainerView from "../../landing-layout/components/ContainerView"

const FeaturesView = () => {
    return (
        <div id="features">
            <ContainerView>
                <div className="md:w-2/3 lg:w-1/2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-secondary">
                        <path fillRule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clipRule="evenodd" />
                    </svg>

                    <h2 className="my-8 text-2xl font-bold text-gray-700 dark:text-white md:text-4xl">
                        Why It Works
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300">Enjoy all of the powerful features the Autopilot Pro has to help your business grow and run more efficiently. You'll be able to save countless hours per week by using our robust automation features.</p>

                </div>
                <div
                    className="mt-16 grid divide-x divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-3 lg:divide-y-0 xl:grid-cols-3"
                >
                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">


                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Proven Strategies
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    At The Autopilot Pro, we utilize tried-and-true digital marketing strategies that have been validated through real-world applications. Our tools are designed to enhance your marketing efforts and drive results, backed by data and expert insights.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">

                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Comprehensive Resources
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    Our extensive library of training materials, templates, and guides covers every aspect of digital marketing. Whether you’re a beginner or an experienced marketer, you’ll find resources tailored to your skill level, ensuring you have the knowledge to succeed.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">

                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Cutting-Edge Tools
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    We provide state-of-the-art tools that streamline your marketing processes. From automation software to analytics dashboards, our solutions simplify complex tasks, allowing you to focus on what matters most—growing your business.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">

                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Actionable Insights
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    With our tools, you gain access to powerful analytics and reporting features. These insights help you understand your audience better, track your campaign performance, and make data-driven decisions to optimize your strategies.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">


                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Expert Guidance
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    Our team of industry professionals is here to support you. With regular webinars, Q&A sessions, and expert articles, you’ll have access to invaluable knowledge and tips that can elevate your marketing efforts.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">

                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Customizable Solutions
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    Recognizing that every business is unique, our tools and services are designed to be flexible and customizable. Tailor our solutions to fit your specific goals and objectives, ensuring maximum effectiveness.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">

                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Ongoing Development
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    The digital marketing landscape is constantly evolving, and so are we. We are committed to continuously improving our offerings based on user feedback and industry trends, ensuring you always have the most relevant tools at your disposal.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="relative space-y-8 py-12 p-8">


                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                    Focus on ROI
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                    We prioritize strategies that deliver tangible results. Our tools are designed to maximize your return on investment, helping you achieve your marketing goals efficiently and effectively.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div
                        className="group relative bg-gray-50 dark:bg-gray-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10"
                    >
                        <div
                            className="relative space-y-8 py-12 p-8 transition duration-300 group-hover:bg-white dark:group-hover:bg-gray-800"
                        >
                           
                            <div className="space-y-2">
                                <h5
                                    className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
                                >
                                  Conclusion
                                </h5>
                                <p className="text-gray-600 dark:text-gray-300">
                                At The Autopilot Pro, we empower digital marketers with the tools, resources, and support they need to succeed. Our commitment to excellence and continuous improvement ensures that you have everything you need to elevate your marketing efforts and achieve your business objectives.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </ContainerView>
        </div>
    )
}

export default FeaturesView