import ToolView from "./ToolView"

const MyToolsView = () => {
    return (
        <>
            <h1
                className="text-black dark:text-gray-600 text-2xl md:text-4xl font-bold my-8"
            >
                My Tools
            </h1>
            <ToolView />
        </>
    )
}

export default MyToolsView