
import ContainerView from "../landing-layout/components/ContainerView"

const FAQView = () => {
    const faqs = [
            {
                show: true,
                question: "What is The Autopilot Pro?",
                answer: "The Autopilot Mobile is a resource hub designed specifically for digital marketers. We offer a range of tools, including training programs, email leads, and other essential resources to help you promote your business effectively."
            },
            {
                show: false,
                question: "What types of training / products do you offer?",
                answer: "We provide various training modules, including SEO strategies, social media marketing, email marketing, and conversion optimization. We have many digital marketing programs essential to you, including Legacy Builders Program, Infinity Processing System, Learn and Earn Profits Online, UBC, Roadmap Exclusive, Selling With Confidence Program, and many others. Our trainings/services are designed for both beginners and experienced marketers."
            },
            {
                show: false,
                question: "How can I access the email leads?",
                answer: "Once you sign up on our website, you will gain access to our marketplace where you can purchase email leads specific to your niche and tailored to your target audience. You can download leads directly from your dashboard."
            },
            {
                show: false,
                question: "What features will be available on the dashboard?",
                answer: "Our dashboard will provide a user-friendly interface where you can track your leads, manage your packages, access marketing tools, view the marketplace, and view your referral rewards."
            },
            {
                show: false,
                question: "How does the referral system work?",
                answer: "Our referral system allows you to earn rewards by referring new users to The Autopilot Pro. You’ll receive a unique referral link, and for every successful sign-up, you’ll earn cash that can be redeemed for usage in the Autopilot Pro's marketplace."
            },
            {
                show: false,
                question: "Can I sell my own products in the marketplace?",
                answer: "Yes! The marketplace will allow users to list and sell their own digital marketing tools and resources. You’ll just need to sign up as a seller and follow our guidelines for listing your products."
            },
            {
                show: false,
                question: "Is there a subscription fee?",
                answer: "No, there is no subscription fee. You just have to log in to your account and purchase whichever product you want from the marketplace."
            },
            {
                show: false,
                question: "What support do you offer?",
                answer: "We offer comprehensive customer support through email and live chat. Our team is here to help you with any questions or issues you may encounter."
            },
            {
                show: false,
                question: "How do I stay updated on new features and resources?",
                answer: "You can subscribe to our newsletter to receive updates on new features, resources, and exclusive promotions directly to your inbox."
            },
            {
                show: false,
                question: "How do I get started?",
                answer: "Simply visit our website, and sign up. You’ll have immediate access to our resources and tools once your account is activated."
            },
            {
                show: false,
                question: "What if I don’t have enough money to purchase the products?",
                answer: "You can just refer and earn enough to purchase our awesome products."
            }
        ]
    
    return (
        <main
            className="space-y-40 mb-40"
        >
            <ContainerView>
                <div className="relative pt-36 ml-auto">
                    <div className="lg:w-2/3 text-center mx-auto mb-10">
                        <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"> <span className="text-primary dark:text-white">Frequently Asked Questions</span></h1>
                    </div>
                </div>


                <div className="flex flex-col gap-5 items-center">

                    {
                        faqs.map((faq, index) =>
                            <details key={index + 1} className="rounded bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 w-full md:w-[600px] p-5">
                                <summary className="cursor-pointer text-black dark:text-white">
                                    <h1 className="inline text-black dark:text-white text-xl font-semibold">{faq.question}</h1>
                                </summary>
                                <p className="text-base font-thin text-black dark:text-white">{faq.answer}</p>

                            </details>)
                    }



                </div>


            </ContainerView>



        </main>
    )
}

export default FAQView