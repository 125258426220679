import type { FC } from "react"
import TransactionListItemView from "./TransactionListItemView"

interface TransactionListViewProps{
    transactions:ITransaction[]
}
const TransactionListView:FC<TransactionListViewProps> = (props) => {
  return (
    <div className="flex flex-col gap-2">
        {
            props.transactions.map((transaction)=><TransactionListItemView key={transaction.id} transaction={transaction}/>)
        }
    </div>
  )
}

export default TransactionListView