import { FC } from "react"
import UserListItemView from "./UserListItemView"

interface Props{
  users:IUser[]
}
const UserListView:FC<Props> = (props) => {
  return (
    <div className="flex flex-col gap-2">
      {
            props.users.map((user)=> <UserListItemView key={user.uid} user={user}/>)
        }
    </div>
  )
}

export default UserListView