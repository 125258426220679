
import { FC, useEffect, useState } from "react"

import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore"
import { db } from "../../../../firebase/client"
import ManageToolsResourcesHistoryListView from "../../../admin-views/manage-tools/components/ManageToolsResourcesHistoryListView"

interface Props {
    toolId: string
}
const ToolsResourcesHistoryView:FC<Props> = (props) => {

    const [toolResources, setToolResources] = useState<IToolResource[]>([])
    const [lastVisibleTool, setLastVisibleTool] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)



    useEffect(() => {
        (async () => {
            try {


                setIsLoading(true)
                const first = query(collection(db, "tool-resources"), where("toolId", "==", props.toolId), orderBy("createdAt", "desc"), limit(10));
                const documentSnapshots = await getDocs(first);
                documentSnapshots.forEach((doc) => {
                    setToolResources(prev => [...prev, doc.data() as IToolResource])
                })
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                setLastVisibleTool(lastVisible)
                setIsLoading(false)


            } catch (error: any) {
                console.log(error.message);

            }
        })()
    }, [props.toolId])

    return (
        <div className="flex flex-col justify-between min-h-[600px]">
            <div className="grow flex flex-col justify-between mb-5">
                {
                    isLoading ? <>
                        <div className="flex flex-col gap-2">
                            {
                                [1, 2, 3, 4, 5].map((_, index) =>
                                    <div key={`${index + 1}`} className="animate-pulse h-8 bg-slate-700 rounded">

                                    </div>)
                            }
                        </div>
                    </> : <ManageToolsResourcesHistoryListView toolResources={toolResources} />
                }

                {
                    lastVisibleTool ?
                        <div className="flex items-center w-full justify-center">
                            <button
                                onClick={async () => {
                                 
                                        setIsLoadingMore(true)
                                        const more = query(collection(db, "tool-resources"), where("toolId", "==", props.toolId), orderBy("createdAt", "desc"), startAfter(lastVisibleTool), limit(10));
                                        const documentSnapshots = await getDocs(more);
                                        documentSnapshots.forEach((doc) => {
                                            setToolResources(prev => [...prev, doc.data() as IToolResource])
                                        })
                                        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                                        setLastVisibleTool(lastVisible)
                                        setIsLoadingMore(false)
                                    

                                }}
                                className="mt-10 relative flex h-10 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                disabled={isLoadingMore}
                            >
                                {
                                    isLoadingMore ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 animate-spin w-5 stroke-white dark:stroke-dark">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                        : <span className="relative text-base font-semibold text-white dark:text-dark"
                                        >Show more</span>
                                }

                            </button>
                        </div>
                        : <></>
                }
            </div>

        </div>
    )
}

export default ToolsResourcesHistoryView