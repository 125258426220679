import UserResourceListsView from "./components/UserResourceListsView"
import UserResourcesView from "./components/UserResourcesView"
import UserView from "./components/UserView"

const ManageUsersView = () => {
  return (
    <>
      <h1
        className="text-black dark:text-gray-600 text-2xl md:text-4xl font-bold my-8"
      >
        Users
      </h1>
      <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-3 gap-3">
        <UserView />
        <UserResourcesView />
        <UserResourceListsView />
      </div>
    </>
  )
}

export default ManageUsersView