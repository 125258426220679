import type { FC } from "react"

interface MarketplaceListSkeletonViewProps {
    isShorcut?: boolean
}
const MarketplaceListSkeletonView: FC<MarketplaceListSkeletonViewProps> = (props) => {
    return (

        <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-10  gap-2">
            <div className="col-span-1"></div>
            <div className="col-span-8">

                {/* <div className={`grid grid-flow-row grid-cols-1 ${props.isShorcut ? 'lg:grid-cols-2' : 'lg:grid-cols-4'}  gap-2`}>
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_, index) =>
                            <div key={`${index + 1}`} className="flex flex-col  justify-between  p-4  rounded col-span-1 h-[200px] group relative bg-white dark:bg-gray-800 ">

                                <div className="animate-pulse h-7 bg-slate-700 rounded"></div>
                                <div className="animate-pulse h-3 bg-slate-700 rounded"></div>
                                <div className="animate-pulse h-8 rounded-xl bg-slate-700"></div>

                            </div>)
                    }
                </div> */}

                <div className={"flex flex-col gap-2"}>
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_, index) =>
                            <div key={`${index + 1}`} className="animate-pulse flex flex-col  justify-between  p-4  rounded col-span-1 h-[100px] group relative bg-white dark:bg-gray-800 ">

                            </div>)
                    }
                </div>


            </div>
            <div className="col-span-1"></div>
        </div>



    )
}

export default MarketplaceListSkeletonView