import RegisterFormView from "./RegisterFormView"

const RegisterView = () => {
  return (
    <div className="w-full h-[100vh]  flex flex-col items-center justify-center">
      <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl mb-5"> <span className="text-primary dark:text-white">Register</span></h1>


      <RegisterFormView />
    </div>
  )
}

export default RegisterView