import { doc, getDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { db } from "../../../firebase/client"
import FullAutomationFormView from "./forms/FullAutomationFormView"

const ToolSetupView = () => {
    const { toolId } = useParams()
    const navigate = useNavigate()

    const [tool, setTool] = useState<ITool | undefined>(undefined)
    const [isLoadingTool, setIsLoadingTool] = useState(false)

    const [product, setProduct] = useState<IProduct | undefined>(undefined)
    const [isLoadingProduct, setIsLoadingProduct] = useState(false)

    const [formName, setFormName] = useState("")

    const [alreadyFilled, setAlreadyFilled] = useState(false)


    useEffect(() => {
        (async () => {
            if (toolId) {
                setIsLoadingTool(true)
                const toolRef = doc(db, "tools", toolId as string)
                const toolDoc = await getDoc(toolRef)
                if (toolDoc.exists()) {
                    setTool(toolDoc.data() as ITool)
                }
                setIsLoadingTool(false)
            }
        })()
    }, [toolId])




    useEffect(() => {
        (async () => {
            if (tool?.productId) {
                setIsLoadingProduct(true)
                const productDocRef = doc(db, "products", tool?.productId)
                const productDoc = await getDoc(productDocRef)
                if (productDoc.exists()) {
                    setProduct(productDoc.data() as IProduct)
                }
                setIsLoadingProduct(false)
            }

        })()
    }, [tool?.productId])


    useEffect(() => {
        if (product) {
            if (product.formName) {
                setFormName(product.formName)
            }
        }

    }, [product])

    useEffect(() => {
        if (tool?.setup) {
            setAlreadyFilled(true)
        }

    }, [tool])



    // useEffect(() => {
    //     if (product?.setupForm) {

    //         const setupForm = product?.setupForm

    //         let _formFields: Record<string, string> = {}

    //         setupForm.fields.forEach((field, index) => {
    //             if (field.children) {
    //                 let _children_formFields: Record<string, string> = {}
    //                 field.children.forEach((children_field, children_index) => {

    //                     _children_formFields[`field_${index}_${children_index}`] = ""
    //                 })
    //                 //@ts-ignore
    //                 _formFields[`field_${index}`] = _children_formFields
    //             } else {
    //                 _formFields[`field_${index}`] = ""
    //                 _formFields[`field_0_others`] = ""
    //                 _formFields[`field_1_product_count`] = ""
    //                 _formFields[`field_1_product_extra_leads`] = ""
    //             }

    //         })

    //         setFormFields(_formFields)

    //     }

    // }, [product?.setupForm])


    return (
        <div>
            {
                isLoadingTool || isLoadingProduct ?
                    <div className="animate-pulse bg-gray-100 dark:bg-gray-800 h-11 rounded"></div>
                    : <>
                        <h1
                            className="text-black dark:text-gray-600 text-2xl md:text-4xl font-bold mt-8"
                        >
                            {product?.title} Setup
                        </h1>
                        <div className="flex items-center gap-2 flex-wrap">
                            <span className="text-black dark:text-white font-thin underline decoration-primary underline-offset-2 cursor-pointer" onClick={() => { navigate("/dashboard/marketplace") }}>My Tools</span>
                            <span className="text-black dark:text-white font-thin">&#62;</span>
                            <span className="text-black dark:text-gray-600 font-thin"> {product?.title}</span>
                            <span className="text-black dark:text-white font-thin">&#62;</span>
                            <span className="text-black dark:text-gray-600 font-thin"> Set up</span>
                        </div>

                        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-5 ">
                            <div className="col-span-1"></div>
                            <div className="col-span-3">
                                <div className="my-8  p-4  rounded min-h-[200px] group relative bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">

                                    {
                                        alreadyFilled ? <>
                                            <div className="flex items-center justify-center text-black dark:text-white text-xl h-[200px]">
                                                <a
                                                    href={`/dashboard/my-tools/${toolId}`}
                                                    className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                >
                                                    <span className="relative text-base font-semibold text-white"
                                                    >Go to My Tool</span>
                                                </a>
                                            </div>
                                        </> : <>
                                            {
                                                formName === "full-automation" && toolId ? <FullAutomationFormView toolId={toolId} /> : <></>
                                            }
                                        </>
                                    }




                                </div>
                            </div>
                            <div className="col-span-1"></div>
                        </div>
                    </>
            }

        </div>
    )
}

export default ToolSetupView