
import TransactionHistoryView from './TransactionHistoryView'

const TransactionView = () => {
    return (
        <>
            <h1
                className="text-black dark:text-gray-600 text-2xl md:text-4xl font-bold my-8"
            >
                Transactions
            </h1>
            <TransactionHistoryView />
        </>
    )
}

export default TransactionView