import { 
    // addDoc, collection, 
    doc, getDoc,
    //  getDocs, query, setDoc, Timestamp, updateDoc, where
     } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { db } from "../../../firebase/client"
// import { useUserAuth } from "../../../context/userAuthContext"
// import SubmitButton from "../../../components/forms/inputs/SubmitButton"
// import ModalAlert, { ModalAlertProps } from "../../../components/interaction/ModalAlert"

const MarketplaceProductDetailsView = () => {
    // const { sessionUser, isLoadingUser } = useUserAuth()
    const navigate = useNavigate()
    const { productId } = useParams()
    // const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
    //     options: {
    //         visible: false,
    //         actionClick: () => { },
    //         severity: "success"
    //     }
    // })


    const [product, setProduct] = useState<IProduct | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        (async () => {
            if (productId) {
                setIsLoading(true)
                const productRef = doc(db, "products", productId as string)
                const productDoc = await getDoc(productRef)
                if (productDoc.exists()) {
                    setProduct(productDoc.data() as IProduct)
                }
                setIsLoading(false)
            }
        })()
    }, [productId])


    // const [buyIntent, setBuyIntent] = useState(false)
    // const [isPurchasing, setisPurchasing] = useState(false)

    // const [alreadyBought, setAlreadyBought] = useState(false)

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             if (productId && sessionUser?.uid) {
    //                 const userToolDocs = await getDocs(query(collection(db, "tools"), where("userId", "==", sessionUser.uid), where("productId", "==", productId)))

    //                 setAlreadyBought(userToolDocs.size === 1 ? true : false)
    //             }
    //         } catch (error: any) {

    //         }
    //     })()
    // }, [productId, sessionUser?.uid])

    return (
        <div>
            {/* <ModalAlert options={modalAlert.options} /> */}
            {
                isLoading ? <div className="animate-pulse bg-gray-100 dark:bg-gray-800 h-11 rounded"></div>
                    : <>
                        <div className="flex items-center gap-2 flex-wrap mt-10">
                            <img src={product?.imgUrl} alt={product?.title} className="w-20 aspect-square object-cover" />

                            <h1
                                className="text-black dark:text-gray-300 text-2xl md:text-4xl font-bold mt-8"
                            >
                                {product?.title}
                            </h1>
                        </div>

                        <div className="flex items-center gap-2 flex-wrap">
                            <span className="text-black dark:text-white font-thin underline decoration-primary underline-offset-2 cursor-pointer" onClick={() => { navigate("/dashboard/marketplace") }}>Marketplace</span>
                            <span className="text-black dark:text-white font-thin">&#62;</span>
                            <span className="text-black dark:text-gray-600 font-thin"> {product?.title}</span>
                        </div>

                        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-5 ">
                            <div className="col-span-3">
                                <div className="my-8 flex flex-col  p-4  rounded min-h-[50px] group relative bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">

                                    <p className="text-gray-600 dark:text-gray-300 text-base font-thin">
                                        {product?.caption}
                                    </p>
                                </div>

                                <div className="my-8 flex flex-col  p-4  rounded min-h-[200px] group relative bg-gray-100 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 ">
                                    <h3
                                        className="text-black dark:text-gray-600 text-xl md:text-2xl font-bold"
                                    >
                                        Features
                                    </h3>
                                    <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                                        {
                                            product?.features.map((feature, index) => <li key={index + 1}>{feature}</li>)
                                        }

                                    </ul>
                                </div>
                                <h3
                                    className="text-black dark:text-white text-xl md:text-2xl font-bold"
                                >
                                    Do you want this package?
                                </h3>
                                <a
                                    href="https://tawk.to/chat/67202b914304e3196ad9c721/1ibaqkbsu"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="relative flex h-7 w-full items-center justify-center px-4 py-7 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                >
                                    <span className="relative text-base font-semibold text-white dark:text-dark"
                                    >Contact support to buy</span>
                                </a>


                                {/* {
                                    isLoadingUser ?
                                        <div className="animate-pulse h-12 rounded w-full bg-slate-700"></div>
                                        : <>
                                            {
                                                !alreadyBought ? <>
                                                    {
                                                        buyIntent ? <>
                                                            <span className="relative text-center text-base font-semibold text-white dark:text-dark">
                                                                Are you sure?
                                                            </span>
                                                            <div className="grid grid-flow-col grid-cols-2">
                                                                <button className="text-base font-semibold text-red-700" onClick={() => { setBuyIntent(false) }}>No</button>
                                                                <SubmitButton
                                                                    loading={isPurchasing}
                                                                    disabled={isPurchasing}
                                                                    onClick={async () => {
                                                                        setisPurchasing(true)

                                                                        const user = sessionUser

                                                                        if (user) {
                                                                            const transactionsRef = collection(db, "transactions")
                                                                            const usersRef = doc(db, "users", user.uid)
                                                                            const toolsRef = collection(db, "tools")



                                                                            const transaction = await addDoc(transactionsRef, {
                                                                                userId: sessionUser?.uid,
                                                                                amount: product?.price,
                                                                                scope: "purchase",
                                                                                status: "initiated",
                                                                                productId: product?.id,
                                                                                createdAt: Timestamp.now()
                                                                            })



                                                                            try {



                                                                                if (product?.price && user.balance >= product?.price) {

                                                                                    const amountToDeduct = Math.abs(user.balance) - Math.abs(product?.price)

                                                                                    await updateDoc(usersRef, {
                                                                                        balance: amountToDeduct
                                                                                    })

                                                                                    const tool = await addDoc(toolsRef, {
                                                                                        userId: user.uid,
                                                                                        productId: product?.id,
                                                                                        createdAt: Timestamp.now(),
                                                                                        status: "pending"
                                                                                    })

                                                                                    await setDoc(doc(db, "tools", tool.id), { id: tool.id }, { merge: true })

                                                                                    await setDoc(doc(db, "transactions", transaction.id), {
                                                                                        id: transaction.id,
                                                                                        status: "complete"
                                                                                    }, { merge: true })


                                                                                    setModalAlert((prev) => {
                                                                                        return {
                                                                                            options: {
                                                                                                ...prev.options,
                                                                                                visible: true,
                                                                                                message: `${product?.title} have been purchased successfully`,
                                                                                                severity: "success",
                                                                                                actionClick: () => {
                                                                                                    if (product.setupForm !== undefined) {
                                                                                                        navigate(`/dashboard/my-tools/setup/${tool.id}`)
                                                                                                    } else {
                                                                                                        navigate("/dashboard/my-tools")
                                                                                                    }
                                                                                                },
                                                                                                actionText: product.setupForm !== undefined ? "Go to setup" : "Go to My Tools"
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    await setDoc(doc(db, "transactions", transaction.id), {
                                                                                        id: transaction.id,
                                                                                        status: "failed",
                                                                                        errorMsg: "Insufficient funds"
                                                                                    }, { merge: true })

                                                                                    setModalAlert((prev) => {
                                                                                        return {
                                                                                            options: {
                                                                                                ...prev.options,
                                                                                                visible: true,
                                                                                                message: "Insufficient funds",
                                                                                                severity: "failed",
                                                                                                actionClick: () => {

                                                                                                    setModalAlert((prev) => {
                                                                                                        return {
                                                                                                            options: {
                                                                                                                ...prev.options,
                                                                                                                visible: false
                                                                                                            }
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }





                                                                            } catch (error: any) {

                                                                                await setDoc(doc(db, "transactions", transaction.id), {
                                                                                    id: transaction.id,
                                                                                    status: "failed",
                                                                                    errorMsg: error.message
                                                                                }, { merge: true })
                                                                                setModalAlert((prev) => {
                                                                                    return {
                                                                                        options: {
                                                                                            ...prev.options,
                                                                                            visible: true,
                                                                                            message: error.message,
                                                                                            severity: "failed",
                                                                                            actionClick: () => {

                                                                                                setModalAlert((prev) => {
                                                                                                    return {
                                                                                                        options: {
                                                                                                            ...prev.options,
                                                                                                            visible: false
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        }


                                                                        setisPurchasing(false)
                                                                    }}
                                                                    btnText="Yes" />
                                                            </div>
                                                            <span className="relative text-xs font-semibold text-white dark:text-dark text-center">
                                                                {new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(product?.price || 0)} will deducted from your balance.
                                                            </span>
                                                        </>
                                                            : <>

                                                                {
                                                                    product?.price && sessionUser?.balance ? <>
                                                                        {
                                                                            product.price > sessionUser.balance ? <div className="flex flex-col gap-2 items-center justify-center">
                                                                                <span className="relative text-xs font-semibold text-red-500 text-center">
                                                                                    Your wallet balance is not enough to purchase this product.
                                                                                </span>
                                                                                <button
                                                                                    onClick={() => { navigate("/dashboard/top-up") }}
                                                                                    className="relative flex h-7 w-full items-center justify-center px-4 py-7 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                                                >

                                                                                    <span className="relative text-base font-semibold text-white dark:text-dark"
                                                                                    > Top up wallet</span>
                                                                                </button>
                                                                            </div> : <button
                                                                                onClick={() => { setBuyIntent(true) }}
                                                                                className="relative flex h-7 w-full items-center justify-center px-4 py-7 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                                            >
                                                                                <span className="relative text-base font-semibold text-white dark:text-dark"
                                                                                >Buy {new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(product?.price || 0)}</span>
                                                                            </button>
                                                                        }
                                                                    </> : null
                                                                }



                                                            </>
                                                    }

                                                </> : null
                                            }
                                        </>
                                } */}

                            </div>
                            <div className="col-span-2"></div>
                        </div>
                    </>
            }


        </div>
    )
}

export default MarketplaceProductDetailsView