import { RouteObject } from "react-router-dom"
import AdminLayoutView from "../views/admin-views/admin-layout/AdminLayoutView"
import AdminOverviewView from "../views/admin-views/admin-overview/AdminOverviewView"
import ManageProductView from "../views/admin-views/mange-products/ManageProductView"
import ManageReferralView from "../views/admin-views/manage-referrals/ManageReferralView"
import ManageTransactionsView from "../views/admin-views/manage-transactions/ManageTransactionsView"
import ManageToolsContainerView from "../views/admin-views/manage-tools/components/ManageToolsContainerView"
import ManageUsersContainerView from "../views/admin-views/manage-users/components/ManageUsersContainerView"
import ManageTopupView from "../views/admin-views/manage-topup/ManageTopupView"




const AdminRoutes: RouteObject[] = [
    {
        path: "/manage",
        element: <AdminLayoutView />,
        children: [
            {
                path: "overview",
                element: <AdminOverviewView />
            },
            {
                path: "users",
                element: <ManageUsersContainerView />
            },
            {
                path: "products",
                element: <ManageProductView />
            },
            {
                path: "tools",
                element: <ManageToolsContainerView />
            },
            {
                path: "referrals",
                element: <ManageReferralView />
            },
            {
                path: "transactions",
                element: <ManageTransactionsView />
            },
            {
                path: "topups",
                element: <ManageTopupView />
            },

        ]

    }
]

export default AdminRoutes