import { onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut as signOutUser } from "firebase/auth";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase/client";
import { doc, getDoc } from "firebase/firestore";



interface IUserAuthContext {
    isLoadingUser: boolean;
    sessionUser: IUser | undefined,
    signIn: (dto: SignInArgs, onError: (errMsg: string) => void, onSuccess: () => void, onEmailNotVerified: () => void) => void,
    signOut: (onError: (errMsg: string) => void, onSuccess: () => void) => void
    resendEmailVerification: (onError: (errMsg: string) => void, onSuccess: () => void) => void
    sendPasswordReset: (email: string, onError: (errMsg: string) => void, onSuccess: () => void) => void
}


const UserAuthContext = createContext<IUserAuthContext | undefined>(undefined);

export const useUserAuth = () => {
    const context = useContext(UserAuthContext);
    if (!context) {
        throw new Error("useUserAuth must be used within a UserAuthProvider");
    }
    return context;
};

type UserAuthProviderProps = {
    children: ReactNode;
};


export const UserProvider = ({ children }: UserAuthProviderProps) => {
    const [isLoadingUser, setIsLoadingUser] = useState(false);

    const [sessionUser, setSessionAuthUser] = useState<IUser | undefined>(undefined)

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (sessionUser === undefined && user?.uid) {
                setIsLoadingUser(true)
                if (user) {
                    const uid = user.uid;
                    const docRef = doc(db, "users", uid)
                    const docQuery = await getDoc(docRef)
                    if (docQuery.exists()) {
                        const user = docQuery.data() as IUser
                        setSessionAuthUser(user)
                    }
                } else {
                    setSessionAuthUser(undefined)
                }
                setIsLoadingUser(false)
            }

        });
    }, [sessionUser])


    const signIn = async (dto: SignInArgs, onError: (errMsg: string) => void, onSuccess: () => void, onEmailNotVerified: () => void) => {
        try {
            const credentials = signInWithEmailAndPassword(auth, dto.email, dto.password)
            const docRef = doc(db, "users", (await credentials).user.uid)
            const docQuery = await getDoc(docRef)
            if (docQuery.exists()) {
                const user = docQuery.data() as IUser
                setSessionAuthUser(user)
            }
            if ((await credentials).user.emailVerified === false) {
                onEmailNotVerified()
            } else {
                onSuccess()
            }

        } catch (error: any) {
            onError(error.message)
        }
    }

    const signOut = async (onError: (errMsg: string) => void, onSuccess: () => void) => {
        try {
            await signOutUser(auth)
            setSessionAuthUser(undefined)
            onSuccess()
        } catch (error: any) {
            onError(error.message)
        }
    }


    const resendEmailVerification = async (onError: (errMsg: string) => void, onSuccess: () => void) => {
        try {
            if (auth.currentUser) {
                await sendEmailVerification(auth.currentUser)
                onSuccess()
            }

        } catch (error: any) {
            onError(error.message)
        }
    }
    const sendPasswordReset = async (email: string, onError: (errMsg: string) => void, onSuccess: () => void) => {
        try {

            await sendPasswordResetEmail(auth, email)
            onSuccess()


        } catch (error: any) {
            onError(error.message)
        }
    }


    return (
        <UserAuthContext.Provider
            value={{
                isLoadingUser,
                sessionUser,
                signIn,
                signOut,
                resendEmailVerification,
                sendPasswordReset
            }} >
            {children}
        </UserAuthContext.Provider>
    )
}

