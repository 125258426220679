
import ContainerView from '../../landing-layout/components/ContainerView'

const TestimonialsView = () => {
  return (
    <div className="text-gray-600 dark:text-gray-300" id="reviews">
      <ContainerView>
        <div className="mb-20 space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold text-gray-800 dark:text-white md:text-4xl">
           Success stories
          </h2>
        </div>
        <div className='my-8 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-2 items-center aspect-auto p-8 border border-gray-100 rounded-3xl bg-white dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-gray-600/10 dark:shadow-none'>
          <div >
            <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-2xl">
            Digital Marketing Agency
            </h3>
            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Challenge:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">A small digital marketing agency struggled to generate enough leads to sustain and grow their client base. Despite offering high-quality services, they found it difficult to reach potential clients in a competitive market.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Solution:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">The agency utilized The Autopilot Pro’s advanced email lead generation tools and training resources. They implemented targeted email campaigns that leveraged the platform’s comprehensive lead databases and marketing automation features.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Results:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">Within three months, the agency experienced a 50% increase in qualified leads. Their conversion rate improved by 35%, and they successfully onboarded five new clients, significantly boosting their monthly revenue. The automated follow-up sequences also helped maintain relationships with leads, resulting in higher engagement rates.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Conclusion:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">By adopting The Autopilot Pro’s tools, the agency transformed its lead generation process, leading to sustainable growth and enhanced client acquisition.</p>


          </div>
          <div>
            <img className='aspect-square object-cover' src={require("../../../../assets/images/success-story/pexels-tim-samuel-5838331.jpg")} alt="pexels-tim-samuel-5838331" />
          </div>
        </div>
        <div className='my-8 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-2 items-center aspect-auto p-8 border border-gray-100 rounded-3xl bg-white dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-gray-600/10 dark:shadow-none'>

          <div>
            <img className='aspect-square object-cover' src={require("../../../../assets/images/success-story/pexels-asphotograpy-230544.jpg")} alt="pexels-asphotograpy-230544" />
          </div>
          <div>
            <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-2xl">
              E-commerce Retailer
            </h3>
            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Challenge:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">An e-commerce retailer specializing in handmade products was facing challenges in converting site visitors into paying customers. High traffic but low conversion rates were affecting their revenue.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Solution:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">The retailer integrated The Autopilot Pro’s training on conversion optimization strategies and utilized the platform’s analytics tools to gain insights into customer behavior. They revamped their marketing funnel, improving their product descriptions and call-to-action strategies based on data-driven recommendations.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Results:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">After implementing these strategies, the retailer saw a 40% increase in conversion rates within two months. Their average order value also rose by 15%, leading to a substantial increase in overall sales.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Conclusion:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">The combination of expert training and actionable insights from The Autopilot Pro empowered the retailer to enhance their marketing efforts, resulting in improved sales performance.</p>


          </div>
        </div>

        <div className='my-8 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-2 items-center aspect-auto p-8 border border-gray-100 rounded-3xl bg-white dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-gray-600/10 dark:shadow-none'>
          <div >
            <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-2xl">
              Local Service Provider
            </h3>
            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Challenge:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">A local service provider (plumber) had difficulty attracting new customers and was relying heavily on word-of-mouth referrals, which limited their growth potential.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Solution:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">By subscribing to The Autopilot Pro, the provider accessed targeted email marketing campaigns and customer segmentation strategies. They learned how to identify and reach potential clients in their area through local SEO tactics and engaging email content.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Results:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">In just six weeks, the service provider reported a 60% increase in inquiries from new customers, resulting in a 30% boost in monthly revenue. The targeted campaigns led to more appointments and repeat business, establishing a more stable customer base.</p>


            <h4 className="my-2 text-sm font-bold text-gray-700 dark:text-white md:text-xl">
              Conclusion:
            </h4>
            <p className="text-gray-600 dark:text-gray-300">With The Autopilot Pro’s tools and guidance, the service provider successfully expanded their reach and enhanced their client acquisition strategy, paving the way for long-term growth.</p>


          </div>
          <div>
            <img className='aspect-square object-cover' src={require("../../../../assets/images/success-story/pexels-kseniachernaya-5691536.jpg")} alt="pexels-kseniachernaya-5691536" />
          </div>
        </div>

      </ContainerView>
    </div>
  )
}

export default TestimonialsView