import ContainerView from "../landing-layout/components/ContainerView"

const AboutView = () => {
  return (
    <main
      className="space-y-40 mb-40"
    >
      <ContainerView>
        <div className="relative pt-36 ml-auto">
          <div className="lg:w-2/3 text-center mx-auto mb-10">
            <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"> <span className="text-primary dark:text-white">About Us</span></h1>
          </div>
        </div>


        <h2 className="my-8 text-2xl font-bold text-gray-700 dark:text-white md:text-4xl">
          Welcome to The Autopilot Pro!
        </h2>
        <p className="text-gray-600 dark:text-gray-300">
          At The Autopilot Pro, we understand the dynamic landscape of digital marketing. Our mission is to empower digital marketers with the tools and resources they need to succeed. Whether you’re a seasoned professional or just starting your journey, we provide a comprehensive platform tailored to meet your needs.
        </p>

        <h3 className="my-6 text-xl text-gray-700 dark:text-white ">
          What We Offer
        </h3>
        <p className="text-gray-600 dark:text-gray-300">
          We offer a variety of services designed to support digital marketers, including:
        </p>
        <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
          <li>Training Programs: Our diverse training modules cover critical aspects of digital marketing, from SEO strategies and social media tactics to email marketing and conversion optimization. Each program is designed to provide practical insights and actionable strategies.</li>
          <li>Email Leads: Gain access to a database of verified email leads tailored to your target audience. Our user-friendly dashboard allows you to order and download leads effortlessly.</li>
          <li>Marketplace: Our marketplace is a hub for digital marketing resources. Users can buy, sell, or trade tools, ensuring that you have access to the best resources available.</li>
          <li>Referral System: Our unique referral system allows you to earn rewards by sharing The Autopilot Pro with others. For every successful sign-up through your unique link, you’ll earn cash redeemable for purchase in our marketplacey</li>
        </ul>
        <h3 className="my-6 text-xl text-gray-700 dark:text-white ">
          Our Vision
        </h3>
        <p className="text-gray-600 dark:text-gray-300">
          At The Autopilot Pro, we envision a future where every digital marketer has the tools and knowledge to thrive in their endeavors. We are committed to continuous improvement and innovation, regularly updating our offerings to meet the evolving needs of our users.
        </p>
        <h3 className="my-6 text-xl text-gray-700 dark:text-white ">
          Join Us on Your Journey
        </h3>
        <p className="text-gray-600 dark:text-gray-300">
          We invite you to explore our platform and discover how The Autopilot Pro can elevate your digital marketing efforts. Together, let’s navigate the exciting world of digital marketing and achieve new heights of success.
        </p>
        <h3 className="my-6 text-xl text-gray-700 dark:text-white ">
          Contact Us
        </h3>
        <p className="text-gray-600 dark:text-gray-300">
          If you have any questions or need assistance, please feel free to reach out. We’re here to help you succeed!
        </p>
        <p className="text-gray-600 dark:text-gray-300">
          Feel free to adjust any sections as needed! Let me know if there’s anything else you’d like to modify.
        </p>
      </ContainerView>


    </main>
  )
}

export default AboutView





