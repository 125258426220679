
const ManageReferralView = () => {
  return (
    <div>
      <h1
        className="text-black dark:text-gray-300 text-2xl md:text-4xl font-bold my-8"
      >
        Manage Referrals
      </h1>
    </div>
  )
}

export default ManageReferralView