import { Form, Formik } from "formik"
import SelectFieldInput from "../../../../components/forms/inputs/SelectFieldInput"
import SubmitButton from "../../../../components/forms/inputs/SubmitButton"
import TextFieldInput from "../../../../components/forms/inputs/TextFieldInput"
import { object, string } from "yup"
import { addDoc, collection, doc, setDoc, Timestamp } from "firebase/firestore"
import { db } from "../../../../firebase/client"
import { useManageTools } from "./ManageToolsContext"
import { FC } from "react"

const ManageToolsResourcesHistoryFormValidation = object({
    type: string().required("Type of resource is required!"),
    resource: string().required("Resource is required!"),
    title: string().required("Title is required!").max(70, "Too long!").min(10, "Too short!"),
})

interface Props {
    onUpdate: (resource: IToolResource) => void
    onClose: () => void
}
const ManageToolsResourcesHistoryFormView: FC<Props> = (props) => {

    const { selectedTool } = useManageTools()
    return (
        <div className=" border-t-2 border-t-primary py-5">
            <button onClick={props.onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-red-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </button>
            <Formik
                validationSchema={ManageToolsResourcesHistoryFormValidation}
                initialValues={{
                    type: "",
                    title: "",
                    resource: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const toolResource = await addDoc(collection(db, "tool-resources"), {
                            toolId: selectedTool?.id,
                            title: values.title,
                            link: values.type === "link" ? values.resource : "",
                            message: values.type === "message" ? values.resource : "",
                            type: values.type,
                            createdAt: Timestamp.now()
                        })
                        await setDoc(doc(db, "tool-resources", toolResource.id), {
                            id: toolResource.id
                        }, { merge: true })

                        if (toolResource) {
                            props.onUpdate({
                                id: toolResource.id,
                                toolId: selectedTool?.id || "",
                                title: values.title,
                                link: values.type === "link" ? values.resource : "",
                                message: values.type === "message" ? values.resource : "",
                                type: values.type as IToolResourceType,
                                createdAt: Timestamp.now()
                            })
                        }

                        setSubmitting(false)
                    } catch (error) {

                        setSubmitting(false)
                    }

                }}
            >
                {({ isSubmitting, submitForm, values }) => <Form className="flex flex-col gap-3">

                    <TextFieldInput name="title" label="What is the title of this resource?" />
                    <SelectFieldInput name="type" label="Select type of resource" options={["link", "message"]} />
                    {
                        values.type !== "" ? <>
                            <TextFieldInput name="resource" label={values.type === "link" ? "Enter link" : "Enter message"} />
                        </> : null
                    }

                    <SubmitButton loading={isSubmitting} disabled={isSubmitting} onClick={() => { submitForm() }} btnText="Send" />

                </Form>}

            </Formik>
        </div>
    )
}

export default ManageToolsResourcesHistoryFormView