import { useSearchParams } from "react-router-dom";
import TransactionHistoryView from "../../../dashboard-views/transaction/TransactionHistoryView";
import { useManageUsers } from "./ManageUsersContext";
import ToolView from "../../../dashboard-views/tools/ToolView";

const UserResourceListsView = () => {

    const [searchParams] = useSearchParams();
    const listParam = searchParams.get("list");

    const { selectedUser } = useManageUsers()

    return (
        <div>
            {
                listParam === "transactions" ? <TransactionHistoryView userId={selectedUser?.uid} /> : <></>
            }
            {
                listParam === "tools" ? <ToolView userId={selectedUser?.uid} /> : <></>
            }
        </div>
    )
}

export default UserResourceListsView