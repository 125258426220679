import React from 'react'
import ContainerView from '../../landing-layout/components/ContainerView'

const HeroSectionView = () => {
  return (
    <div className="relative" id="home">
      <div aria-hidden="true" className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>
      <ContainerView>
        <div className="relative pt-36 ml-auto">
          <div className="lg:w-2/3 text-center mx-auto">
            <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl">Move your business to the next level with <span className="text-primary dark:text-white">Autopilot Pro.</span></h1>
            <p className="mt-8 text-gray-700 dark:text-gray-300">We are thrilled to provide our powerful software, training, and setup services tailored for digital marketers, affiliate marketers, and others in the field. Our platform serves as a one-stop shop for all your business needs, offering everything from email leads to social media management and beyond.
            </p>

            <div className="my-16 flex flex-wrap justify-center gap-y-4 gap-x-6">
              <a
                href="/session/signin"
                className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span className="relative text-base font-semibold text-white"
                >Get started</span>
              </a>
              <a
                href="/about"
                className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max"
              >
                <span
                  className="relative text-base font-semibold text-primary dark:text-white"
                >Learn more</span
                >
              </a>
            </div>
            {/* <div className="hidden py-8 mt-16 border-y border-gray-100 dark:border-gray-800 sm:flex justify-between">
              <div className="text-left">
                <h6 className="text-lg font-semibold text-gray-700 dark:text-white">World ClassnclassName Support</h6>
                <p className="mt-2 text-gray-500">We work with you to understand your business and help get your application setup to best fit your needs and support you along the way.</p>
              </div>
              <div className="text-left">
                <h6 className="text-lg font-semibold text-gray-700 dark:text-white">Manual marketing stress</h6>
                <p className="mt-2 text-gray-500">Most users go for our system because of how stressful and time consuming manual marketing is, with no affirmation of sales</p>
              </div>
              <div className="text-left">
                <h6 className="text-lg font-semibold text-gray-700 dark:text-white">Easy to Use</h6>
                <p className="mt-2 text-gray-500">Even though the system has many robust features, you'll enjoy the simplistic design and easy to use interface.</p>
              </div>
            </div> */}
          </div>
          <iframe className="w-full min-h-[150px] md:min-h-[500px] aspect-video  my-5" src="https://www.youtube.com/embed/5aukHk8rRtA?si=2R2WFpwUSwuwpPS8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

          {/* <div className="mt-12 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              <img src={require("../../../../assets/images/ips/ips 1.jpg")} className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" />
            </div>
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              <img src={require("../../../../assets/images/ips/ips 2.png")} className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" />
            </div>
            <div className="p-4 flex grayscale transition duration-200 hover:grayscale-0">
              <img src={require("../../../../assets/images/ips/ips 3.png")} className="h-9 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" />
            </div>

          </div> */}
        </div>
      </ContainerView>
    </div>
  )
}

export default HeroSectionView