import { ErrorMessage, Field } from "formik"
import type { FC } from "react"


interface SelectFieldInputProps {
    name: string
    label?: string
    helper?: string
    placeholder?: string
    options: string[]
}
const SelectFieldInput: FC<SelectFieldInputProps> = (props) => {
    return (
        <div>
            {
                props.label ? <label htmlFor={props.name} className="text-gray-900 dark:text-white">{props.label}</label> : null
            }
            <Field name={props.name} as="select" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                <option value="">Select</option>
                {
                    props.options.map((item, index) => <option key={index + 1} value={item}>{item}</option>)
                }
            </Field>
            <ErrorMessage name={props.name} >{msg => <div className="text-red-700">{msg}</div>}</ErrorMessage>
            {
                props.helper ? <span className="text-gray-900 dark:text-white text-xs">{props.helper}</span> : null
            }
        </div>
    )
}

export default SelectFieldInput