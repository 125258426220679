import { addDoc, collection, doc, getDocs, query, setDoc, Timestamp, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import * as referralCodes from "referral-codes";
import { db } from "../../../firebase/client";
import { useUserAuth } from "../../../context/userAuthContext";
import SubmitButton from "../../../components/forms/inputs/SubmitButton";
import ModalAlert, { ModalAlertProps } from "../../../components/interaction/ModalAlert";
import CopyToClipBoardView from "../../../components/common/CopyToClipBoardView";


const ReferralView = () => {

    const { sessionUser } = useUserAuth()
    const [referral, setReferral] = useState<IReferral | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [refresh, setRefresh] = useState(false)


    const [modalAlert, setModalAlert] = useState<ModalAlertProps>({
        options: {
            visible: false,
            actionClick: () => { },
            severity: "success"
        }
    })

    useEffect(() => {
        (async () => {
            if (sessionUser?.uid) {
                setIsLoading(true)
                const referralQuery = query(collection(db, "referrals"), where("userId", "==", sessionUser?.uid))
                const referralSnapshots = await getDocs(referralQuery)
                if (referralSnapshots.size === 1) {
                    setReferral(referralSnapshots.docs[0].data() as IReferral)
                }

                setIsLoading(false)
            }

        })()

    }, [sessionUser?.uid, refresh])

    return (
        <>
            <ModalAlert options={modalAlert.options} />
            {
                isLoading ? <div className="animate-pulse h-[100px] bg-slate-700 rounded"></div>
                    : <div className="flex flex-col items-start justify-between p-4  rounded col-span-1 lg:w-[300px] w-full h-[100px] group relative bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                        <div className="flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-primary">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                            </svg>

                            <span className="text-sm text-center font-thin text-gray-700 dark:text-white text-ellipsis overflow-hidden grow">
                                {referral === undefined ? "Referral Program" : " Referral link"}
                            </span>
                          
                        </div>


                        {
                            referral === undefined ?
                                <>
                                    <span className="text-black dark:text-white text-[0.5em] font-thin">
                                        Enroll to the referral program and start earning.
                                    </span>
                                    <div className="flex items-center justify-end w-full">
                                        <SubmitButton
                                            btnText="Enable referral"
                                            loading={isProcessing}
                                            disabled={isProcessing}
                                            onClick={async () => {
                                                try {
                                                    setIsProcessing(true)
                                                    const code = referralCodes.generate({
                                                        count: 1,
                                                    })[0] || ""
                                                    const newReferral = await addDoc(collection(db, "referrals"), {
                                                        userId: sessionUser?.uid,
                                                        code,
                                                        balance: 0,
                                                        createdAt: Timestamp.now()
                                                    })

                                                    await setDoc(doc(db, "referrals", newReferral.id), {
                                                        id: newReferral.id
                                                    }, { merge: true })

                                                    setModalAlert((prev) => {
                                                        return {
                                                            options: {
                                                                ...prev.options,
                                                                visible: true,
                                                                message: "Referral account created successfully",
                                                                severity: "success",
                                                                actionClick: () => {
                                                                    setRefresh(true)
                                                                    setModalAlert((prev) => {
                                                                        return {
                                                                            options: {
                                                                                ...prev.options,
                                                                                visible: false
                                                                            }
                                                                        }
                                                                    })
                                                                },
                                                                actionText: "Done"
                                                            }
                                                        }
                                                    })

                                                } catch (error: any) {
                                                    setModalAlert((prev) => {
                                                        return {
                                                            options: {
                                                                ...prev.options,
                                                                visible: true,
                                                                message: error.message,
                                                                severity: "failed",
                                                                actionClick: () => {

                                                                    setModalAlert((prev) => {
                                                                        return {
                                                                            options: {
                                                                                ...prev.options,
                                                                                visible: false
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        }
                                                    })
                                                }
                                                setIsProcessing(false)
                                            }} />
                                    </div>
                                </>
                                :
                                <div className="flex items-center gap-2">

                                    <a href={`https://theautopilotpro.com/session/register?code=${referral.code}`}>
                                        <span className="text-sm text-center font-semibold text-gray-700 dark:text-white underline decoration-primary">
                                            {referral.code}
                                        </span>
                                    </a>
                                    <CopyToClipBoardView text={`https://theautopilotpro.com/session/register?code=${referral.code}`} />
                                </div>
                        }
                    </div>
            }
        </>
    )
}

export default ReferralView