import { createContext, ReactNode, useContext, useState } from "react";


interface IManageToolsContext {
    selectedTool?: ITool
    setSelectedTool: (tool?: ITool|undefined) => void
}

const ManageToolsContext = createContext<IManageToolsContext | undefined>(undefined);

export const useManageTools = () => {
    const context = useContext(ManageToolsContext);
    if (!context) {
        throw new Error("useManageTools must be used within a ManageToolsProvider");
    }
    return context;
};


type ManageToolsProviderProps = {
    children: ReactNode;
};


export const ManageToolsProvider = ({ children }: ManageToolsProviderProps) => {
    const [selectedTool, setselectedTool] = useState<ITool | undefined>(undefined)

    const setSelectedTool = (tool?: ITool|undefined) => {
        setselectedTool(tool)
    }
    return (
        <ManageToolsContext.Provider
            value={{
                selectedTool,
                setSelectedTool
            }} >
            {children}
        </ManageToolsContext.Provider>
    )
}