
import { getCountFromServer, Query } from "firebase/firestore"
import { FC, useEffect, useState } from "react"

interface Props {
    query: Query
}
const QueryCountView: FC<Props> = (props) => {
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const snapshot = await getCountFromServer(props.query);
                setCount(snapshot.data().count)
                setIsLoading(false)
            } catch (error: any) {
                console.log(error.message);

            }
        })()
    }, [props.query])

    return (
        <>
            {
                isLoading ? <>...</>
                    : <>
                        {new Intl.NumberFormat("en-GB").format(count)}
                    </>
            }
        </>
    )
}

export default QueryCountView