import React from 'react'

const AppFooterView = () => {
    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    <div>

                        <span className="block font-bold">Autopilot Pro</span>
                        <p className="mt-4 max-w-xs text-gray-500">
                            Move your business to the next level.
                        </p>

                    </div>

                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
                        <div>

                        </div>

                        <div>
                            <p className="font-medium text-gray-900">Company</p>

                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="/about" className="text-gray-700 transition hover:opacity-75"> About </a>
                                </li>


                            </ul>
                        </div>

                        <div>
                            <p className="font-medium text-gray-900">Helpful Links</p>

                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="/contact" className="text-gray-700 transition hover:opacity-75"> Contact </a>
                                </li>

                                <li>
                                    <a href="/faq" className="text-gray-700 transition hover:opacity-75"> FAQs </a>
                                </li>

                                <li>
                                    <a href="/" className="text-gray-700 transition hover:opacity-75"> Live Chat </a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <p className="font-medium text-gray-900">Legal</p>

                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="/terms-of-use" className="text-gray-700 transition hover:opacity-75"> Terms of Use</a>
                                </li>

                                <li>
                                    <a href="/privacy-policy" className="text-gray-700 transition hover:opacity-75"> Privacy Policy </a>
                                </li>
                                <li>
                                    <a href="/refund-policy" className="text-gray-700 transition hover:opacity-75"> Refund Policy </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <p className="text-xs text-gray-500">&copy; 2024. Autopilot Pro. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default AppFooterView