import ContainerView from "../landing-layout/components/ContainerView"

const PrivacyPolicyView = () => {
    return (
        <main
            className="space-y-40 mb-40"
        >
            <ContainerView>
                <div className="relative pt-36 ml-auto">
                    <div className="lg:w-2/3 text-center mx-auto mb-10">
                        <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"> <span className="text-primary dark:text-white">Privacy Policy</span></h1>
                    </div>
                </div>

                <p className="text-gray-600 dark:text-gray-300">Effective date: August 01, 2019</p>
                <p className="text-gray-600 dark:text-gray-300">At The Autopilot Pro, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Information We Collect
                </h3>
                <p className="text-gray-600 dark:text-gray-300">We collect various types of information in order to provide you with the best experience possible, including:</p>
                <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                    <li>Personal Information: This may include your name, email address, phone number, and payment information when you purchase a product or sign up for an account.</li>
                    <li>Usage Data: We collect data about how you interact with our website, such as your IP address, browser type, pages visited, and time spent on the site.</li>
                    <li>Cookies and Tracking Technologies: We use cookies and similar technologies to track your activity on our website and store certain information.</li>
                </ul>



                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    How We Use Your Information
                </h3>
                <p className="text-gray-600 dark:text-gray-300">The information we collect is used for a variety of purposes, including:</p>
                <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                    <li>To provide and maintain our services: We use your personal data to process transactions, manage your account, and offer customer support.</li>
                    <li>To improve our services: Usage data helps us understand how users interact with our platform and informs improvements to features and user experience.</li>
                    <li>To communicate with you: We may use your contact information to send you updates, marketing materials, and promotional content. You can opt out of these communications at any time.</li>
                    <li>For legal obligations: We may use your information to comply with applicable laws, regulations, and legal processes.</li>
                </ul>


                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    How We Share Your Information
                </h3>
                <p className="text-gray-600 dark:text-gray-300">We do not sell or rent your personal information to third parties. However, we may share your data in the following circumstances:</p>
                <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                    <li>Service Providers: We may share your information with third-party vendors who assist in providing our services, such as payment processors and hosting services.</li>
                    <li>Legal Requirements: We may disclose your information if required by law or to protect our legal rights.</li>
                    <li>Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to a third party.</li>

                </ul>


                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Data Security
                </h3>
                <p className="text-gray-600 dark:text-gray-300">We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission or storage is 100% secure, and we cannot guarantee absolute security.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Your Data Rights
                </h3>
                <p className="text-gray-600 dark:text-gray-300">You have certain rights regarding your personal data, including:</p>
                <ul className="text-gray-600 dark:text-gray-300 list-disc mx-8 ">
                    <li>Access: You can request access to the personal data we hold about you.</li>
                    <li>Correction: You can request that we correct any inaccuracies in your data.</li>
                    <li>Deletion: You can request the deletion of your personal data under certain circumstances.</li>
                    <li>Opt-out: You can opt-out of receiving promotional communications from us at any time.</li>
                </ul>
                <p className="text-gray-600 dark:text-gray-300">To exercise any of these rights, please contact us at <a href="mailto:support@theautopilotpro.com" className="underline decoration-primary ">support@theautopilotpro.com</a></p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                    Third-Party Links
                </h3>
                <p className="text-gray-600 dark:text-gray-300">Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We recommend reviewing their privacy policies before providing any personal information.</p>

                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                Changes to This Privacy Policy
                </h3>
                <p className="text-gray-600 dark:text-gray-300">We may update this Privacy Policy from time to time. When we make changes, we will revise the “Effective Date” at the top of this policy and notify you through our website or via email.</p>


                <h3 className="my-8 text-xl font-bold text-gray-700 dark:text-white md:text-4xl">
                Contact Us
                </h3>
                <p className="text-gray-600 dark:text-gray-300">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:.</p>
                <p className="text-gray-600 dark:text-gray-300">Email: <a href="mailto:support@theautopilotpro.com" className="underline decoration-primary ">support@theautopilotpro.com</a></p>



            </ContainerView>



        </main>
    )
}

export default PrivacyPolicyView