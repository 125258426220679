import { useEffect, useState } from "react"
import ManageToolsResourcesHistoryFormView from "./ManageToolsResourcesHistoryFormView"
import ManageToolsResourcesHistoryListView from "./ManageToolsResourcesHistoryListView"
import { useManageTools } from "./ManageToolsContext"
import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore"
import { db } from "../../../../firebase/client"

const ManageToolsResourcesHistoryView = () => {
  const { selectedTool } = useManageTools()
  const [toolResources, setToolResources] = useState<IToolResource[]>([])
  const [lastVisibleTool, setLastVisibleTool] = useState<any>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)


  const [formVisibility, setFormVisibility] = useState(false)
  const toggleFormVisibility = () => {
    setFormVisibility(!formVisibility)
  }

  const [tabIndex, setTabIndex] = useState(0)
  const tabs = ["Setup", "Resources"]
  useEffect(() => {
    (async () => {
      try {
        if (selectedTool) {

          setIsLoading(true)
          const first = query(collection(db, "tool-resources"), where("toolId", "==", selectedTool.id), orderBy("createdAt", "desc"), limit(10));
          const documentSnapshots = await getDocs(first);
          documentSnapshots.forEach((doc) => {
            setToolResources(prev => [...prev, doc.data() as IToolResource])
          })
          const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
          setLastVisibleTool(lastVisible)
          setIsLoading(false)
        }

      } catch (error: any) {
        console.log(error.message);

      }
    })()
  }, [selectedTool])

  return (
    <div className="flex flex-col  min-h-[600px]">
      <div className="flex items-center my-4">
        {
          tabs.map((tab, index) =>
            <div
              key={tab}
              className={`text-black dark:text-white font-light px-4 py-2 w-fit flex items-center ${tabIndex === index ? "bg-primary rounded-full" : ""}`}
              onClick={() => {

                setTabIndex(index)
              }}
            >
              {tab}
            </div>
          )
        }
      </div>

      <div className="flex flex-col gap-2">
        {
          tabIndex === 0 ? <>
            {
              selectedTool?.setup?.map((elem, index) =>
                <div key={index} className="text-black dark:text-white flex flex-col">
                  <span className="text-sm font-light">{elem.label}</span>
                  {
                    typeof elem.answer === "string" ? <span className="text-xs font-thin">{elem.answer}</span> : <></>
                  }
                  {
                    Array.isArray(elem.answer) ? <>

                      {
                        elem.answer.map((item) => <span className="text-xs font-thin">{item}</span>)
                      }
                    </> : <></>
                  }

                </div>
              )
            }
          </> : <></>
        }

        <hr />
        {
          selectedTool?.pendingPayment ? <div className="text-black dark:text-white flex flex-col">
            <span className="text-sm font-light"> Payment</span>
            <span className="text-xs font-thin">{new Intl.NumberFormat("en-GB", { currency: "GBP", style: "currency" }).format(selectedTool.pendingPayment.amount)}</span>
            <span className="text-xs font-thin">Have paid ? {selectedTool.pendingPayment.paid ? "Yes" : "No"}</span>

          </div> : <></>
        }

      </div>

      {
        tabIndex === 1 ? <>

          <div className="grow flex flex-col justify-between mb-5">

            {
              isLoading ? <>
                <div className="flex flex-col gap-2">
                  {
                    [1, 2, 3, 4, 5].map((_, index) =>
                      <div key={`${index + 1}`} className="animate-pulse h-8 bg-slate-700 rounded">

                      </div>)
                  }
                </div>
              </> : <ManageToolsResourcesHistoryListView toolResources={toolResources} />
            }

            {
              lastVisibleTool ?
                <div className="flex items-center w-full justify-center">
                  <button
                    onClick={async () => {
                      if (selectedTool) {
                        setIsLoadingMore(true)
                        const more = query(collection(db, "tool-resources"), where("toolId", "==", selectedTool.id), orderBy("createdAt", "desc"), startAfter(lastVisibleTool), limit(10));
                        const documentSnapshots = await getDocs(more);
                        documentSnapshots.forEach((doc) => {
                          setToolResources(prev => [...prev, doc.data() as IToolResource])
                        })
                        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                        setLastVisibleTool(lastVisible)
                        setIsLoadingMore(false)
                      }

                    }}
                    className="mt-10 relative flex h-10 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                    disabled={isLoadingMore}
                  >
                    {
                      isLoadingMore ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 animate-spin w-5 stroke-white dark:stroke-dark">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                        : <span className="relative text-base font-semibold text-white dark:text-dark"
                        >Show more</span>
                    }

                  </button>
                </div>
                : <></>
            }
          </div>
          {
            formVisibility ? <ManageToolsResourcesHistoryFormView
              onClose={toggleFormVisibility}
              onUpdate={(resource) => {
                setToolResources(prev => [resource, ...prev])
              }}
            /> : <>
              <button className="flex items-center w-fit rounded bg-purple-50 px-4 py-2" onClick={toggleFormVisibility}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-6 stroke-primary">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>
                <span className="text-primary text-sm ">Send a resource</span>
              </button>
            </>
          }
        </> : <></>
      }


    </div>
  )
}

export default ManageToolsResourcesHistoryView