
const TransactionListSkeletonView = () => {
    return (
        <div className="flex flex-col gap-2">
            {
                [1, 2, 3, 4, 5].map((_, index) =>
                    <div key={`${index + 1}`} className="animate-pulse h-16 bg-slate-700 rounded">

                    </div>)
            }
        </div>
    )
}

export default TransactionListSkeletonView