import { doc, getDoc } from "firebase/firestore"
import { useEffect, useState, type FC } from "react"
import { db } from "../../../../firebase/client"
import { useNavigate } from "react-router-dom"

interface ToolListItemViewProps {
    tool: ITool
    isShorcut?: boolean
}
const ToolListItemView: FC<ToolListItemViewProps> = (props) => {
    const [product, setProduct] = useState<IProduct | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [needsSetup, setNeedsSetup] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const productDocRef = doc(db, "products", props.tool.productId)
            const productDoc = await getDoc(productDocRef)
            if (productDoc.exists()) {
                setProduct(productDoc.data() as IProduct)
            }
            setIsLoading(false)
        })()
    }, [props.tool.productId])

    useEffect(() => {
        if (product?.setupForm && props.tool.setup === undefined) {
            setNeedsSetup(true)
        }
    }, [product, props.tool])



    if (isLoading) {
        return <div className="animate-pulse h-16 bg-slate-700 rounded">

        </div>
    }
    return (
        <div onClick={() => { navigate(`/dashboard/my-tools/${props.tool.id}`) }} className="relative flex  gap-2 items-center  rounded col-span-1 min-h-[50px] group  bg-gray-50 dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 cursor-pointer">

            <img src={product?.imgUrl} alt={product?.title} className={`${props.isShorcut ? "w-16" : "lg:w-32 w-16"} aspect-square object-cover`} />
            <div className={`${props.isShorcut ? "min-h-[60px]" : "lg:min-h-[100px] min-h-[60px]"} flex flex-col justify-start items-start grow `}>
                <h2 className={`${props.isShorcut ? "text-sm w-[50%]" : "lg:text-xl lg:w-full text-sm w-[50%]"}  text-left font-semibold text-gray-700 dark:text-white truncate `}>
                    {product?.title}
                </h2>
                <p className={`text-sm font-thin text-gray-900 dark:text-gray-100 truncate overflow-clip ${props.isShorcut ? "w-[70%]" : "w-full"}`}>
                    {product?.caption}
                </p>
                {
                    needsSetup ? <div className="text-red-400 text-sm font-light flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-4 stroke-red-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                        </svg>

                        Needs setup
                    </div> : <></>
                }
            </div>

        </div>
    )
}

export default ToolListItemView