import React, { FC, ReactNode } from 'react'

interface ContainerViewProps {
    children: ReactNode
}
const ContainerView: FC<ContainerViewProps> = ({ children }) => {
    return (
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
            {children}
        </div>
    )
}

export default ContainerView