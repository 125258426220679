import { Outlet } from "react-router-dom";
import AppFooterView from "./components/AppFooterView";
import AppHeaderView from "./components/AppHeaderView";
//@ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const LandingLayoutView = () => {
  return (
    <div className="bg-white dark:bg-gray-900">
      <AppHeaderView />
      <Outlet />
      <AppFooterView />
      <TawkMessengerReact
        propertyId="67202b914304e3196ad9c721"
        widgetId="1ibaqkbsu" />
    </div>
  )
}

export default LandingLayoutView