import {
  RouteObject,
} from "react-router-dom";
import SessionLayoutView from "../views/session-views/session-layout/SessionLayoutView";
import SignInView from "../views/session-views/signin/SignInView";
import RegisterView from "../views/session-views/register/RegisterView";
import VerifyEmailView from "../views/session-views/verify-email/VerifyEmailView";
import ForgotPasswordView from "../views/session-views/forgot-password/ForgotPasswordView";

const SessionRoutes: RouteObject[] = [
  {
    path: "/session",
    element: <SessionLayoutView />,
    children: [
      {
        path: "signin",
        element: <SignInView />
      },
      {
        path: "register",
        element: <RegisterView />
      },
      {
        path: "verify-email/:email",
        element: <VerifyEmailView />
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordView />
      },
    ]

  }
]

export default SessionRoutes