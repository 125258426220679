import { Outlet, useNavigate } from "react-router-dom";
import AppHeaderView from "../../landing-views/landing-layout/components/AppHeaderView";
import AppFooterView from "../../landing-views/landing-layout/components/AppFooterView";
import { useEffect } from "react";
import { auth } from "../../../firebase/client";
import { useUserAuth } from "../../../context/userAuthContext";


const SessionLayoutView = () => {
  const navigate = useNavigate()
  const user = auth.currentUser
  const { isLoadingUser, sessionUser } = useUserAuth()
  useEffect(() => {
    if (user !== null) {
      if (user.emailVerified) {
        if (sessionUser?.persona === 'admin') {
          navigate("/manage/overview")
        }
        if (sessionUser?.persona === 'customer') {
          navigate("/dashboard/home")
        }

      } else {
        navigate(`/session/verify-email/${user.email}`)
      }
    }
  }, [navigate, user, isLoadingUser, sessionUser])


  return (
    <div className="bg-white dark:bg-gray-900">
      <AppHeaderView />
      <Outlet />
      <AppFooterView />
    </div>
  )
}

export default SessionLayoutView