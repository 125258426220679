import { useEffect, useState } from "react"
import { collection, query, orderBy, limit, getDocs, where } from "firebase/firestore";
import { db } from "../../../firebase/client";
import { useUserAuth } from "../../../context/userAuthContext";
import TransactionListSkeletonView from "./components/TransactionListSkeletonView";
import TransactionListView from "./components/TransactionListView";


const RecentTransactionsView = () => {
  const [transactions, setTransactions] = useState<ITransaction[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const {  sessionUser } = useUserAuth()

  useEffect(() => {
      (async () => {
          try {
              setIsLoading(true)
              const transactionsQuery = query(collection(db, "transactions"), where("userId", "==", sessionUser?.uid), orderBy("createdAt", "desc"), limit(5));
              const documentSnapshots = await getDocs(transactionsQuery);
              documentSnapshots.forEach((doc) => {
                  setTransactions(prev => [...prev, doc.data() as ITransaction])
              })
              setIsLoading(false)
          } catch (error) {

          }
      })()
  }, [sessionUser?.uid])
  return (
      <div className="flex flex-col gap-3">
          <div className="flex flex-row justify-between">
              <h4 className="text-xl text-left font-semibold text-gray-700 dark:text-white">
                  Recent transactions
              </h4>

              <a href="/dashboard/transactions"  className="relative text-base font-semibold text-primary">View all</a>
          </div>

          {
              isLoading ? <TransactionListSkeletonView /> : <TransactionListView transactions={transactions} />
          }

      </div>
  )
}

export default RecentTransactionsView